module.exports={
    "global": [
        "error400s"
    ],
    "homepage": [
        "homepage"
    ],
    "product": [
        "productDetail",
        "viewItemDetails"
    ],
    "productSet": [
        "productDetail",
        "viewItemDetails"
    ],
    "orderconfirmation": [
        "purchase",
        "orderConfirmation"
    ],
    "shipping": [
        "productsInCart"
    ],
    "billing": [
        "productsInCart"
    ],
    "cart": [
        "removeFromCart",
        "removeItemsFromCart",
        "cartPageView",
        "viewCartDetails"
    ],
    "checkout": [
        "checkoutPageView"
    ],
    "search": [
        "plpPageView"
    ],
    "clp": [
        "plpPageView"
    ],
    "plp": [
        "plpPageView"
    ],
    "searchnohits": [
        "nosearchPageView"
    ],
    "category": [
        "plpPageView",
        "viewCategoryPage"
    ],
    "account": [
        "accountView"
    ],
    "checkoutlogin": [
        "checkoutloginView"
    ],
    "newsletter": [
        "newsletterPageView"
    ],
    "countryselector":[
        "countrySelectorView"
    ]
}