import Subscription from '_core_ext/components/forms/Subscription';
import eventMgr from '_core_ext/core/eventMgr';

export default class NewsletterForm extends Subscription {
    init() {
        super.init();

        this.event('focus', '.js-newsletter-email input[type=email]', this.onEmailInputFocus);
    }

    onEmailInputFocus() {
        this.$el.find('.js-toggle_content').fadeIn();
        this.$el.find('.js-result_message').hide();
    }

    handleSubmitFailed(result) {
        super.handleSubmitFailed(result);
        this.$el.get(0).reset();
    }

    onSubmitSuccess(result) {
        this.$el.trigger('newsletterSubscription.success', [this.$el]);
        this.$el.find('.js-emailform-wrapper').hide();
        this.$el.find('.js-toggle_content').hide();
        this.$el.find('.js-result_message').fadeIn();
        this.$el.get(0).reset();
    }
}