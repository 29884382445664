/* eslint no-redeclare: 0 */

var utils = require('./utils');
var coreExtUtils = require('./../../util');
var addedCounter = 0;
module.exports = {
    'productImpression': function (analyticsData, pushDataLayer) {
        var analyticsData = analyticsData || {},
            $productsDetails = $('#main .js-product-impression'),
            impressionObj = {};

            var products = [];           

            $productsDetails.each(function () {
                var $this = $(this);
                var isDuplicate = $this.parents('.swiper-slide-duplicate:not(.swiper-slide-active)').length;

                if (utils.isImpressionVisible($this) && !isDuplicate && !$this.data('productViewed')) {
                    var productData = $this.data('productDetails');
                    var list;
                    if(pageContext.type === 'search') {
                        list = 'Search';
                    } else {
                        list = productData.list
                    }
                    var recommendationsCarouselData = $this.parents('.recommendations-carousel');
                    var recommendationsCarouselTitle = recommendationsCarouselData.data('display-title');
                    var recentlyViewedDiv = $this.parents('.recently-viewed-carousel');
                    
                    if (recentlyViewedDiv.length > 0) {
                        list = recentlyViewedDiv.find('.recently-viewed-carousel__title')[0].innerHTML;
                    } else if (recommendationsCarouselData.length > 0 && recommendationsCarouselTitle !== undefined) {
                        list = recommendationsCarouselData.data('display-title');
                    }

                    if (productData) {
                        var productConfigJson;
                        var productPosition = $this.parents('li').index() + 1;
                        if (recommendationsCarouselData.length > 0 && recommendationsCarouselTitle !== undefined || recentlyViewedDiv.length > 0){
                            productConfigJson = $this.data('json-config');
                            productPosition = parseInt(productConfigJson.productPosition);
                        }
                        products.push({
                            'name': productData.name,
                            'id': productData.id,
                            'price': productData.price,
                            'brand': productData.brand,
                            'category': productData.category,
                            'variant': productData.variant,
                            'list': list,
                            'position': productPosition 
                        });
                    }

                    $this.data('productViewed', true);
                }
            });

            if (products.length) {
                impressionObj = {
                    'event': 'impression',
                    'ecommerce': {
                        'currencyCode': analyticsData.page ? analyticsData.page.currencyCode : '',
                        'impressions': products
                    }
                };

                products = [];
                if (typeof(pushDataLayer) === 'undefined' || pushDataLayer) {
                    dataLayer.push(impressionObj);
                } else {
                    delete impressionObj.event;
                    return impressionObj;
                }
            }
        
    },

    //productImpression Event in UA - viewItemList Event in ga4
    //for PLP, CLP, Search result, no search result, PDP recommendations, Cart recommendations
    'viewItemList': function (analyticsData, pushDataLayer) {
        var analyticsData = analyticsData || {},
            $productsDetails = $('#main .js-product-impression'),
            selectedView = $('.c-product-result__list-grid'),
            selectedViewValue = 'not apply',i,impressionObj = {},products = [], list, imageType='',discountDifference='', discountPercentage = '', fullPrice = '';     
            if($(selectedView).data('cmp') == 'gridView'){
                selectedViewValue = $(selectedView).data('json-config').preSelectedViewMode;
            }
            $productsDetails.each(function () {
                var $this = $(this);
                var item_concatcategory = 'not apply',categoryArray=[];
                var isDuplicate = $this.parents('.swiper-slide-duplicate:not(.swiper-slide-active)').length;
                if (utils.isImpressionVisible($this) && !isDuplicate && !$this.data('productViewedItem')) {
                    var productData = $this.data('productDetails');
                    if(pageContext.type === 'search') {
                        list = 'Search';
                    } else {
                        list = productData.list
                    }
                    var recommendationsCarouselData = $this.parents('.recommendations-carousel');
                    var recommendationsCarouselTitle = recommendationsCarouselData.data('display-title');
                    var recentlyViewedDiv = $this.parents('.recently-viewed-carousel');
                    
                    if (recentlyViewedDiv.length > 0) {
                        list = recentlyViewedDiv.find('.recently-viewed-carousel__title')[0].innerHTML;
                    } else if (recommendationsCarouselData.length > 0 && recommendationsCarouselTitle !== undefined) {
                        list = recommendationsCarouselData.data('display-title');
                    }
                    
                    //imageType is model/product
                    var imageSrcUrl = $(this).find('.image-src-url')[0].value || $(this).find('.swiper-slide-active .image-src-url')[0].value;
                    if(imageSrcUrl.includes('FL') ){
                        imageType = "product";
                    } else if(imageSrcUrl.includes('MO')){
                        imageType = "model";
                    }
                    
                    if(productData.category){
                        item_concatcategory = productData.category;
                        categoryArray = item_concatcategory.split("/");
                    }
                    if($this.data('product-details')){
                        discountDifference = parseFloat($this.data('product-details').standardPrice - $this.data('product-details').price).toFixed(2).replace(/[.,]00/, "");
                        discountPercentage = $this.data('product-details').discount;
                        fullPrice = $this.data('product-details').standardPrice;
                    }
                    if (productData) {
                        var productConfigJson;
                        var productPosition = $this.parents('li').index() + 1;
                        if (recommendationsCarouselData.length > 0 && recommendationsCarouselTitle !== undefined || recentlyViewedDiv.length > 0){
                            productConfigJson = $this.data('json-config');
                            productPosition = parseInt(productConfigJson.productPosition);
                        }
                        products.push({
                            'item_name' : productData.name,
                            'item_id' : productData.id,
                            'price': productData.price,
                            'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                            'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                            'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                            'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                            'item_concatcategory' : item_concatcategory,
                            'item_variant': productData.variant,
                            'item_wash': (productData.wash && productData.wash!='undefined' ? productData.wash : 'not apply'),
                            'item_variation_group': (productData.wash && productData.wash!='undefined' ? (productData.id + productData.wash) : (productData.id + productData.variant)),
                            'item_list_name': list,
                            'item_list_id': list,
                            'index': productPosition,
                            'quantity': 1,
                            'discount' : (discountDifference ? discountDifference : '0'),
                            'item_discount_percentage': (discountPercentage?discountPercentage : '0'),
                            'item_full_price': ((discountDifference == 0) ? 'yes' : 'no'),
                            'item_image_type': imageType,
                            'item_grid_type': selectedViewValue
                        });
                    }

                    $this.data('productViewedItem', true);
                }
            });

            if (products.length) {
                impressionObj = {
                    'event': 'view_item_list',
                    'ecommerce': {
                        'items': products
                    }
                };
                products = [];
                if (typeof(pushDataLayer) === 'undefined' || pushDataLayer) {
                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                    dataLayer.push(impressionObj);
                } else {
                    delete impressionObj.event;
                    return impressionObj;
                }
            }
        
    },

    'productDetail': function (analyticsData) {
        var analyticsData = analyticsData || {};
        var productData = analyticsData.product || {};

        /* eslint dot-notation: 0 */
        if (location.hash) {
            var hashParams = coreExtUtils.getQueryStringParams(location.hash.substr(1));
            if (hashParams && hashParams['start']) {
                productData.position = hashParams['start'];
            }
        }

        if (window.sessionStorage.productPosition && window.sessionStorage.actionFieldList) {
            productData.position = window.sessionStorage.productPosition ? window.sessionStorage.productPosition : 1;
            productData.list = window.sessionStorage.actionFieldList ? window.sessionStorage.actionFieldList : productData.list;
        } else {
            const now = new Date();
            const hrefVal = location.href;
            const hrefPath = location.pathname+location.search;
            productData.position = 1;
            var productDataStored = '';
            var productActionPositon = JSON.parse(localStorage.getItem('productActionPositon') || '[]');
            if (productActionPositon.length > 0) {
                productActionPositon = $.grep(productActionPositon, (item) => {
                    if ((item.hrefVal === hrefVal || item.hrefVal === hrefPath) && now.getTime() <= item.expiry) {
                        productDataStored = item;
                        return false;
                    } else if (now.getTime() > item.expiry) {
                        return false;
                    } else {
                        return true;
                    }
                });
                localStorage.setItem('productActionPositon', JSON.stringify(productActionPositon));
            }

            if (productDataStored !== '') {
                productData.position = productDataStored.productPosition ? productDataStored.productPosition : 1;
                productData.list = productDataStored.actionFieldList ? productDataStored.actionFieldList : productData.list;
                window.sessionStorage.productPosition = productData.position;
                window.sessionStorage.actionFieldList = productData.list;
            } else {
                productData.list = 'Direct';
                window.sessionStorage.actionFieldList = productData.list;
            }
        }

        var sessionIdVal = $('#wrapper').find('#sessionId');
        var sessionIdValue = sessionIdVal.val();

        var analyticsEvent = {
            'event': 'pageview',
            'system': analyticsData.systemData,
            'store': analyticsData.storeData,
            'content': analyticsData.contentData,
            'user': pageContext.user,
            'user':{
                'loginState': pageContext.user.loginState,
                'sessionId': sessionIdValue
            },
            'category': analyticsData.categoryData,
            'extraData': analyticsData.extraData,
            'ecommerce': {
                'currencyCode': analyticsData.page ? analyticsData.page.currencyCode : '',
                'detail': {
                    'actionField': {
                        'list': productData.list
                    },
                    'products': [utils.createProductObject(productData)]
                }
            }
        }
        if(pageContext.user.loginState=='logged'){
            analyticsEvent.user.email = pageContext.user.email;
        }
        if (SitePreferences.ATTRAQT_ENABLED) {
            var sourceIdValue = '';
            var sourceIdVal = $('#wrapper').find('#sourceId');
            if(sourceIdVal.length > 0){
                sourceIdValue = sourceIdVal.val();
            }
            var attraqtdata = {
                'action': 'view',
                'target': {
                    'product': pageContext.currentProductID
                },
                'metadata': {
                    'locale': pageContext.currentLocale
                },
                'sourceId': sourceIdValue,
                'sessionId': sessionIdValue
            }
            analyticsEvent.attraqtdata = attraqtdata;
        }
        dataLayer.push(analyticsEvent);     
        
        dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
        var newPageData = utils.getNewAdvancePageData(analyticsData);
        dataLayer.push(newPageData);
    },

    //producteDetails Event in UA - viewItemDetails Event in ga4
    //for PDP page load
    'viewItemDetails': function (analyticsData) {
        var analyticsData = analyticsData || {},
            productData = analyticsData.product || {},
            selectedViewValue = 'not apply', item_concatcategory = 'not apply', categoryArray,
            list=productData.list, imageType='',discountDifference='',discountPercentage='';     
            if(window.sessionStorage.gridViewType){
                selectedViewValue = window.sessionStorage.gridViewType;
            }
            
            //imageType is model/product
            var imageSrcUrl = $('.js-zoom img').data('src');
            if(imageSrcUrl.includes('FL') ){
                imageType = "product";
            } else if(imageSrcUrl.includes('MO')){
                imageType = "model";
            }
            
            if(productData.category){
                item_concatcategory = productData.category;
                categoryArray = item_concatcategory.split("/");
            }
            discountDifference = parseFloat(productData.standardPrice - productData.price).toFixed(2).replace(/[.,]00/, "");
            discountPercentage = productData.discount;

        var analyticsEvent = {
            'event': 'view_item',
            'ecommerce': {
                'currency': (analyticsData.page ? analyticsData.page.currencyCode : ''),
                'value': analyticsData.product.price,
                'items': [{
                    'item_name' : productData.name,
                    'item_id' : productData.id,
                    'price': productData.price,
                    'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                    'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                    'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                    'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                    'item_concatcategory' : item_concatcategory,
                    'item_variant': productData.variant,
                    'item_wash': (productData.wash && productData.wash!='undefined' ? productData.wash : 'not apply'),
                    'item_variation_group': (productData.wash && productData.wash!='undefined' ? (productData.id + productData.wash) : (productData.id + productData.variant)),
                    'item_list_name': list,
                    'item_list_id': list,
                    'index': productData.position,
                    'quantity': 1,
                    'discount' : (discountDifference ? discountDifference : '0'),
                    'item_image_type': imageType,
                    'item_grid_type': selectedViewValue,
                    'item_size': (productData.dimension15 ? productData.dimension15 : 'not apply'),
                    'item_length': (productData.length ? productData.length : 'not apply'),
                    'item_full_price': ((discountDifference == 0) ? 'yes' : 'no'),
                    'item_discount_percentage': (discountPercentage ? discountPercentage : '0')
                }]
            }
        }
        dataLayer.push(analyticsEvent);
    },

    //runs for category - PLP page and not search result page
    'viewCategoryPage': function(analyticsData){
        if(pageContext.type ==='plp' && pageContext.currentPage === 'category'){
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'view_category',
                'eventData': {
                    'category': pageContext.analytics.contentData.pageTitle
                }
            });
        }
    },

    //error_400 Event - New in GA4
    //comes for error (nohits and notfound template) pages
    //nofound - HKT, FCN and nohits - HKT, FCN, PPJ
    'error400s': function (analyticsData) {
        var isErrorPage = $('.pt_product-search-noresult');
        if(isErrorPage.length > 0){
            setTimeout(function() {
                dataLayer.push({
                    'event': 'error_400s',
                    'eventData':{
                        'event_category': 'page_error',
                        'event_label': pageContext.siteId,
                        'error_type': 'error_400s'
                    }
                });
            }, 2000);
        }
    },

    //No event in UA - viewCart Event in GA4
    //for Cart page load
    'viewCartDetails': function (analyticsData) {
        var categoryValue='funnel_cart';
        var item_concatcategory = 'not apply', categoryArray = [];
        var imageType = '', discountDifference = '', discountPercentage = '', fullPrice = '';
        var selectedView = $('.js-cmp-gridView');
        var selectedViewValue = 'not apply';     
        if(selectedView.data('cmp') == 'gridView'){
            selectedViewValue = selectedView.data('json-config').preSelectedViewMode;
        }

        var productsDetails = '', productsPurchase = [];
        if(pageContext.analytics.basket && pageContext.analytics.basket.ecommerce.length > 0){
            productsDetails = pageContext.analytics.basket.ecommerce;
            for (var i = 0; i < productsDetails.length; i++) {
                var $this = productsDetails[i]
                if ($this) {
                    discountDifference = parseFloat($this.standardPrice - $this.price).toFixed(2).replace(/[.,]00/, "");
                    discountPercentage = $this.discount;
                    fullPrice = $this.standardPrice
                }
            
                if ($this.category) {
                    item_concatcategory = $this.category;
                    categoryArray = item_concatcategory.split("/");
                }

                var imageSrcUrl = $this.imageUrl;
                if(imageSrcUrl && imageSrcUrl.includes('FL')){
                    imageType = "product";
                } else if(imageSrcUrl && imageSrcUrl.includes('MO')){
                    imageType = "model";
                }
                productsPurchase.push({
                    'item_id': $this.id,
                    'item_name': $this.name,
                    'price': $this.price,
                    'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                    'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                    'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                    'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                    'item_concatcategory' : item_concatcategory,
                    'item_variant': $this.variant,
                    'item_wash': ($this.wash && $this.wash!='undefined' ? $this.wash : 'not apply'),
                    'item_variation_group': ($this.wash && $this.wash!='undefined' ? ($this.id + $this.wash) : ($this.id + $this.variant)),
                    'item_size': ($this.dimension15 ? $this.dimension15 : 'not apply'),
                    'item_length': ($this.length ? $this.length : 'not apply'),
                    'item_list_name': $this.list,
                    'item_list_id': $this.list,
                    'index': $this.position,
                    'quantity': $this.quantity,
                    'discount': (discountDifference ? discountDifference : '0'),
                    'item_image_type': imageType,
                    'item_grid_type': $this.item_grid_type,
                    'item_full_price': ((discountDifference == 0) ? 'yes' : 'no'),
                    'item_discount_percentage': (discountPercentage ? discountPercentage : '0')
                });
            }
        }
        var analyticsEvent = {
            'event': 'view_cart',
            'ecommerce':{
                'event_category' :  categoryValue,
                'currency': pageContext.analytics.page.currencyCode,
                'value': ( $('.c-order-summary__button.js-proceed-checkout').data('basket-info') ? $('.c-order-summary__button.js-proceed-checkout').data('basket-info') : '0' ),
                'items': productsPurchase
            }
        }
        dataLayer.push(analyticsEvent);
    },

    'promoImpression': function () {
        var promoImpressions = [];

        $('.js-promo-impression').each(function () {
            var $this = $(this),
                promoDetails = $this.data('promoDetails');

            if (utils.isImpressionVisible($this) && !$this.data('promoViewed')) {
                if (promoDetails) {
                    promoImpressions.push({
                        'id': promoDetails.id,
                        'name': promoDetails.name,
                        'creative': promoDetails.creative,
                        'position': promoDetails.position
                    });
                }

                $this.data('promoViewed', true);
            }
        });

        if (promoImpressions.length) {
            dataLayer.push({
                'ecommerce': {
                    'promoView': {
                        'promotions': [promoImpressions]
                    }
                }
            });

            promoImpressions = [];
        }
    },

    'purchase': function (analyticsData) {
        var analyticsData = analyticsData || {};
        var orderData = analyticsData.purchase;

        if (!orderData) {
            return;
        }

        var products = [];
        var productsPurchase = [];
        var actionField = orderData.actionField || {};
        for (var i = 0; i < orderData.products.length; i++) {
            var product = orderData.products[i];
            var formattedProductData = utils.createProductObject(product);
            // This is specific to attarqt and rest should remain same for existing flow
            if (SitePreferences.ATTRAQT_ENABLED) {
                var sessionIdVal = $('#wrapper').find('#sessionId');
                var sessionIdValue = sessionIdVal.val();
                sessionIdValue = sessionIdVal.val(); 

                productsPurchase[i] = {
                    'action':'purchase',
                    'target': {
                        'product': product.id + product.variant
                    },
                    'metadata': {
                        'orderId': actionField.id,
                        'price': product.price,
                        'quantity': product.quantity,
                        'currency': actionField.currencyCode,
                        'locale' : pageContext.currentLocale
                    },
                    'sessionId': sessionIdValue,
                    'ecommerceId': actionField.email
                }
            }
            formattedProductData.quantity = product.quantity;
            formattedProductData.dimension28 = product.list;
            formattedProductData.item_grid_type = product.item_grid_type;
            products.push(formattedProductData);
        }

        var ecomObj = {
            'currencyCode': analyticsData.page ? analyticsData.page.currencyCode : '',
            'purchase': {
                'actionField': {
                    'id': actionField.id,
                    'affiliation': Resources.AFFILIATION,
                    'revenue': actionField.revenue,
                    'tax': actionField.tax,
                    'shipping': actionField.shipping,
                    'revenueWithoutShipping': actionField.revenueWithoutShipping,
                    'coupon': actionField.coupon,
                    'newCustomer': actionField.newCustomer
                },
                'products': products
            }
        };

        var pageData = utils.getAdvancePageData(analyticsData, ecomObj);
        if (SitePreferences.ATTRAQT_ENABLED) {
            pageData.attarqtdata = productsPurchase;
        }
        dataLayer.push(pageData);

        dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
        var newPageData = utils.getNewAdvancePageData(analyticsData);
        dataLayer.push(newPageData);
    },

    //purchase Event in UA - orderConfirmation Event in ga4
    //for order confirmation page
    'orderConfirmation': function (analyticsData) {
        dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
        var analyticsData = analyticsData || {};
        var orderData = analyticsData.purchase;

        if (!orderData) {
            return;
        }

        var productsPurchase = [];
        var attraqtdata = [];
        var actionField = orderData.actionField || {};
        var item_concatcategory = 'not apply', categoryArray = [];
        var imageType = '', discountDifference = '', discountPercentage = '', fullPrice = '';
        var selectedView = $('.js-cmp-gridView');
        var selectedViewValue = 'not apply';     
        if(selectedView.data('cmp') == 'gridView'){
            selectedViewValue = selectedView.data('json-config').preSelectedViewMode;
        }
        for (var i = 0; i < orderData.products.length; i++) {
            var $this = orderData.products[i];
            if ($this) {
                discountDifference = parseFloat($this.standardPrice - $this.price).toFixed(2).replace(/[.,]00/, "");
                discountPercentage = $this.discount;
                fullPrice = $this.standardPrice
                if ($this.category) {
                    item_concatcategory = $this.category;
                    categoryArray = item_concatcategory.split("/");
                }

                var imageSrcUrl,
                cartObject = $('.c-order-table__photo .js-image')[i];
                imageSrcUrl = $(cartObject).data('zoom-src');

                if(imageSrcUrl.includes('FL') ){
                    imageType = "product";
                } else if(imageSrcUrl.includes('MO')){
                    imageType = "model";
                }
                productsPurchase.push({
                    'item_id': $this.id,
                    'item_name': $this.name,
                    'price': $this.price,
                    'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                    'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                    'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                    'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                    'item_concatcategory' : item_concatcategory,
                    'item_variant': $this.variant,
                    'item_wash': ($this.wash ? $this.wash : 'not apply'),
                    'item_variation_group': ($this.wash ? ($this.id + $this.wash) : ($this.id + $this.variant)),
                    'item_size': ($this.dimension15 ? $this.dimension15 : 'not apply'),
                    'item_length': ($this.length ? $this.length : 'not apply'),
                    'item_list_name': $this.list,
                    'item_list_id': $this.list,
                    'index': $this.position,
                    'quantity': $this.quantity,
                    'discount': (discountDifference ? discountDifference : '0'),
                    'item_image_type': imageType,
                    'item_grid_type': $this.item_grid_type,
                    'item_full_price': ((discountDifference == 0) ? 'yes' : 'no'),
                    'item_discount_percentage': (discountPercentage ? discountPercentage : '0')
                });
                if (SitePreferences.ATTRAQT_ENABLED) {
                    var product = orderData.products[i];
                    var sessionIdVal = $('#wrapper').find('#sessionId');
                    var sessionIdValue = sessionIdVal.val();
                    sessionIdValue = sessionIdVal.val(); 
    
                    attraqtdata[i] = {
                        'action':'purchase',
                        'target': {
                            'product': product.id + product.variant
                        },
                        'metadata': {
                            'orderId': actionField.id,
                            'price': product.price,
                            'quantity': product.quantity,
                            'currency': actionField.currencyCode,
                            'locale' : pageContext.currentLocale
                        },
                        'sessionId': sessionIdValue,
                        'ecommerceId': actionField.email
                    };
                }
            }
        }

        var coupon= pageContext.analytics.purchase.actionField.coupon;
        var shippingMethodFromStorage = localStorage.getItem('selectedShippingCombinedValue');
        var ecomObj = {
            'event': 'purchase',
            'ecommerce':{
                'currency': pageContext.analytics.page.currencyCode,
                'value': (orderData.actionField.revenue ? orderData.actionField.revenue : '0'),
                'coupon': (coupon ? coupon : undefined),
                'transaction_id': (pageContext.analytics.purchase.actionField.id ? pageContext.analytics.purchase.actionField.id : ''),
                'im_shipping_tier': (shippingMethodFromStorage ? shippingMethodFromStorage : ''),
                'shipping': orderData.actionField.shipping,
                'tax': orderData.actionField.tax,
                'im_payment_type': pageContext.analytics.extraData.paymentType,
                'email': pageContext.user.mhash,
                'ecomm_totalvalue': orderData.actionField.ecomTotal,
                'items': productsPurchase
            },
            'attraqtdata': attraqtdata
        };
        dataLayer.push(ecomObj);
    },

    'cartPageView': function (analyticsData) {
        var analyticsData = analyticsData || {};
        var checkoutData, pageData;
        if (analyticsData) {
            if(analyticsData.checkout){
                //non-empty cart scenario
                var checkoutData = utils.getCheckoutData(analyticsData, analyticsData.checkout.step);
                pageData = utils.getAdvancePageData(analyticsData, checkoutData);
            } else {
                //empty cart scenario
                pageData = utils.getAdvancePageData(analyticsData);
            }

            dataLayer.push(pageData);

            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var newPageData = utils.getNewAdvancePageData(analyticsData);
            dataLayer.push(newPageData);
        }
    },

    'checkoutPageView': function (analyticsData) {
        if (analyticsData && analyticsData.checkout) {
            var checkoutData = utils.getCheckoutData(analyticsData, analyticsData.checkout.step);
            var pageData = utils.getAdvancePageData(analyticsData, checkoutData);

            dataLayer.push(pageData);

            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var newPageData = utils.getNewAdvancePageData(analyticsData);
            dataLayer.push(newPageData);
        }
    },

    'plpPageView': function (analyticsData) {
        var analyticsData = analyticsData || {};
        var pageData = utils.getAdvancePageData(analyticsData);
        if (SitePreferences.ATTRAQT_ENABLED) {
            var pageToPath = analyticsData.contentData.pageTitle;
            var sourceIdVal = $('.pt_product-search-result').find('#sourceId');
            var sessionIdVal = $('#wrapper').find('#sessionId');
            var sourceIdValue, sessionIdValue;
            sessionIdValue = sessionIdVal.val(); 
                if(sourceIdVal.length > 0){
                    sourceIdValue = sourceIdVal.val(); 
                }
            
            var attraqtdata = {
                'action': 'view',
                'target': {
                    'page': pageToPath,
                },
                'metadata': {
                    'locale': pageContext.currentLocale
                },
                'sourceId': sourceIdValue,
                'sessionId': sessionIdValue
            }
             
            var searchTermFromUrl = coreExtUtils.getParameterValueFromUrl('q', window.location.search);
            if (searchTermFromUrl) {
                var searchTerm = searchTermFromUrl;
                attraqtdata.target['search-terms'] = searchTerm;
            }
            pageData.attraqtdata = attraqtdata;
        }
        dataLayer.push(pageData);

        if(pageContext.siteId.indexOf("HKT") > -1 || pageContext.siteId.indexOf("PJG") > -1){
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var newPageData = utils.getNewAdvancePageData(analyticsData);
            dataLayer.push(newPageData);
        }
        var ecomObj = module.exports.productImpression(analyticsData, true);
            if (ecomObj && 'ecommerce' in ecomObj) {
                ecomObj = ecomObj['ecommerce'];
            }
            
        var ecomObj = module.exports.viewItemList(analyticsData, true);
            if (ecomObj && 'ecommerce' in ecomObj) {
                ecomObj = ecomObj['ecommerce'];
            }  
        dataLayer.push(analyticsData, ecomObj);
        $(document).on('plp.productLoaded', function(){
            if(pageContext.siteId.indexOf("FCN") > -1){
                dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                var newPageData = utils.getNewAdvancePageData(analyticsData);
                dataLayer.push(newPageData);
            }
            var ecomObj = module.exports.productImpression(analyticsData, true);
            if (ecomObj && 'ecommerce' in ecomObj) {
                ecomObj = ecomObj['ecommerce'];
            }
            var ecomObj = module.exports.viewItemList(analyticsData, true);
            if (ecomObj && 'ecommerce' in ecomObj) {
                ecomObj = ecomObj['ecommerce'];
            }  
        dataLayer.push(analyticsData, ecomObj);
        });
    },

    'nosearchPageView': function (analyticsData) {
        var analyticsData = analyticsData || {};
        dataLayer.push(utils.getAdvancePageData(analyticsData));

        dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
        var newPageData = utils.getNewAdvancePageData(analyticsData);
        dataLayer.push(newPageData);
    },

    'homepage': function (analyticsData) {
        var analyticsData = analyticsData || {};
        dataLayer.push(utils.getPageData(analyticsData));

        dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
        var newPageData = utils.getNewAdvancePageData(analyticsData);
        dataLayer.push(newPageData);
    },

    'accountView': function (analyticsData) {
        var analyticsData = analyticsData || {};
        dataLayer.push(utils.getAdvancePageData(analyticsData));

        dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
        var newPageData = utils.getNewAdvancePageData(analyticsData);
        dataLayer.push(newPageData);
    },

    'checkoutloginView': function (analyticsData) {
        var analyticsData = analyticsData || {};
        dataLayer.push(utils.getAdvancePageData(analyticsData));

        dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
        var newPageData = utils.getNewAdvancePageData(analyticsData);
        dataLayer.push(newPageData);
    },

    'newsletterPageView': function (analyticsData) {
        var analyticsData = analyticsData || {};
        dataLayer.push(utils.getPageData(analyticsData));

        dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
        var newPageData = utils.getNewAdvancePageData(analyticsData);
        dataLayer.push(newPageData);
    },

    'countrySelectorView': function (analyticsData) {
        var analyticsData = analyticsData || {};
        dataLayer.push(utils.getPageData(analyticsData));
        
        dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
        var newPageData = utils.getNewAdvancePageData(analyticsData);
        dataLayer.push(newPageData);
    }
};
