/** The references object of all modules needed for components initialization */
var references = {
    /** Page module references */
    // 'compare': require('_core/pages/compare'),
    'storelocator': require('./../../../app_storefront_core/cartridge/js/pages/storelocator'),
    'address': require('./pages/address'),

    /** Core level components */
    'countries': require('./../../../app_storefront_core/cartridge/js/countries'),
    // 'rating': require('_core/rating'),

    /** Components from extension cartridge */
    'gdpr': require('./gdpr'),
    'page': require('./page'),
    'global-events': require('./global-events'),
    'layout': require('./layout'),
    'overlay': require('./overlay'),
    'dropdown': require('./dropdown'),
    'set-background': require('./set-background'),
    'billing': require('./pages/checkout/billing'),
    'gtm': require('./analytics/gtm'),
    'header': require('./header'),
    //'carousel': require('_core_ext/carousel'),
    'picture-lazy-load': require('./picture-lazy-load'),
    'recommendations': require('./pages/product/recommendations'),
    'tabs': require('./pages/tabs'),
    'checkout': require('./pages/checkout'),
    'creditcardValidator': require('./pages/checkout/creditcard-validator'),

    /** 3rd-party integration modules */
    'postcodeanywhere': require('./postcodeanywhere'),
    'video': require('./video'),
    'livechat': require('./components/common/LiveChat').default,
    'parcellab': require('./components/parcellab/parcellab'),
    'userzoom': require('./components/userZoom/UserZoom').default,

    /** Components */
    'block': require('./core/common/Block').default,
    'accordion': require('./components/common/Accordion').default,
    'countrySelectForm': require('./components/localization/CountrySelectForm').default,
    'customSelect': require('./components/common/CustomSelect').default,
    'productMainImageNav': require('./components/product/ProductMainImageNav').default,
    'dialog': require('./components/common/Dialog').default,
    'tooltip': require('./components/common/Tooltip').default,
    'addressTile': require('./components/account/AddressTile').default,
    'accountNavigation': require('./components/account/AccountNavigation').default,
    'loyaltyJoin': require('./components/loyalty/LoyaltyJoin').default,
    'measurementsForm': require('./components/account/MeasurementsForm').default,
    'registerWithLoyaltyCheck': require('./components/loyalty/RegisterWithLoyaltyCheck').default,
    'loginWithLoyaltyCheck': require('./components/loyalty/LoginWithLoyaltyCheck').default,
    'iframeVideo': require('./components/common/IFrameVideo').default,
    'tooltipcontent': require('./components/common/TooltipContent').default,
    'headerNavigationContainer': require('./components/header/HeaderNavigation').default,
    'headerSearchContainer': require('./components/header/HeaderSearch').default,

    /** Checkout Components */
    /** Shipping */
    'shippingMethod': require('./components/checkout/shipping/shippingMethods/ShippingMethod').default,
    'shippingMethodsSelector': require('./components/checkout/shipping/shippingMethods/Selector').default,
    'shippingRegion': require('./components/checkout/shipping/shippingRegions/Region').default,
    'shippingRegionsSelector': require('./components/checkout/shipping/shippingRegions/Selector').default,
    'homeShippingForm': require('./components/checkout/shipping/shippingForms/Home').default,
    'upsShippingForm': require('./components/checkout/shipping/shippingForms/Ups').default,
    'correosExpressShippingForm': require('./components/checkout/shipping/shippingForms/CorreosExpress').default,
    'inStoreShippingForm': require('./components/checkout/shipping/shippingForms/InStore').default,
    'shipping': require('./components/checkout/shipping/Shipping').default,
    'locationsPicker': require('./components/locationspicker/LocationsPicker').default,
    'locationsMap': require('./components/locationspicker/locationsmap/LocationsMap').default,
    'locationsList': require('./components/locationspicker/locationslist/LocationsList').default,
    /**Billing */
    /** Summary */
    'checkoutSummary': require('./components/checkout/checkoutSummary/CheckoutSummary').default,
    /**AddressList */
    'addressList': require('./components/checkout/addressList/AddressList').default,

    /** PLP */
    'productView': require('./components/plp/viewModes/product/Product').default,
    'gridView': require('./components/plp/viewModes/grid/Grid').default,
    'sorting': require('./components/plp/sorting/Sorting').default,
    'searchControls': require('./components/plp/searchControls/SearchControls').default,

    /** PDP */
    'pdp': require('./components/pdp/Pdp').default,
    'pdpVideos': require('./components/pdp/pdpVideos/PdpVideos').default,

    /** Form components */
    'form': require('./components/forms/FormGeneric').default,
    'addressForm': require('./components/forms/AddressForm').default,
    'passwordResetForm': require('./components/forms/PasswordResetForm').default,
    'accountPaymentForm': require('./components/forms/AccountPaymentForm').default,
    'inputText': require('./components/forms/InputText').default,
    'inputHidden': require('./components/forms/InputHidden').default,
    'inputSelect': require('./components/forms/InputSelect').default,
    'button': require('./components/forms/Button').default,
    'inputCheckbox': require('./components/forms/InputCheckbox').default,
    'inputRadio': require('./components/forms/InputRadio').default,
    'captchaForm': require('./components/forms/CaptchaForm').default,
    'registrationForm': require('./components/forms/Registration').default,
    'changePasswordForm': require('./components/forms/ChangePassword').default,
    'changeAccountInfoForm': require('./components/forms/Subscription').default,
    'changeLoginForm': require('./components/forms/ChangeLogin').default,
    'cmp-gdpr-script': require('./cmp-gdpr-script'),
};

/**
 * The components initialization configuration object
 *
 * @example New "Page" configuration
 *  var configuration = {
 *      //...
 *      'newpage' : {
 *          'enabled' : true,
 *          'options' : {},
 *          'components' : {
 *              'pagination' : {
 *                  'enabled' : false,
 *                  'options' : {}
 *              }
 *          }
 *      }
 *      //...
 *  }
 */
var configuration = {
    'global': {
        'components': {
            'page': {},
            'gdpr': {},
            'inputSelect': {},
            'customSelect': {},
            'layout': {},
            'picture-lazy-load': {},
            'stickyElement': {},
            'carousel': {},
            'recommendations': {},
            'minicart': {},
            'tooltip': {},
            'overlay': {},
            'form': {},
            'common-elements': {},
            'searchsuggest': {},
            'set-background': {},
            'header': {},
            'headerContainer': {},
            'headerNavigationContainer': {},
            'headerSearchContainer': {},
            'dropdown': {},
            'backToTop': {},
            'accordion': {},
            'newsletterForm': {},
            'registrationForm': {},
            'changePasswordForm': {},
            'changeAccountInfoForm': {},
            'changeLoginForm': {},
            'gtm': {},
            'cmp-gdpr-script': {},
            'global-events': {},
            'tabs': {},
            'video': {},
            'countrySelector': {},
            'countrySelectForm': {},
            'dialog': {},
            'block': {},
            'inputText': {},
            'inputHidden': {},
            'button': {},
            'action': {},
            'inputCheckbox': {},
            'inputRadio': {},
            'captchaForm': {},
            'livechat': {},
            'userzoom': {},
            'tooltipcontent': {}
        }
    },
    'account': {
        'components': {
            'postcodeanywhere': {},
            'addressForm': {},
            'addressTile': {},
            'accountPaymentForm': {},
            'passwordResetForm': {},
            'accountNavigation': {},
            'registerWithLoyaltyCheck': {},
            'loginWithLoyaltyCheck': {},
            'measurementsForm': {},
            'address': {},
            'parcellab': {}
        }
    },
    'cart': {},
    'checkout': {
        'components': {
            'postcodeanywhere': {},
            'creditcardValidator': {},
            'passwordResetForm': {},
            'loginWithLoyaltyCheck': {},
            'addressForm': {},
            'address': {},
            'shippingMethod': {},
            'shippingMethodsSelector': {},
            'shippingRegion': {},
            'shippingRegionsSelector': {},
            'shippingFormsContainer': {},
            'homeShippingForm': {},
            'upsShippingForm': {},
            'correosExpressShippingForm': {},
            'inStoreShippingForm': {},
            'shipping': {} ,
            'checkoutSummary': {},
            'addressList': {},
            'locationsPicker': {},
            'locationsMap': {},
            'locationsList': {}
        }
    },
    'compare': {},
    'product': {
        'components': {
            'pdp': {},
            'pdpVideos': {},
            'iframeVideo': {},
            'emailme': {},
            'zoom': {},
            'productMainImageNav': {enabled: false}
        }
    },
    'registry': {},
    'search': {
        'components': {
            'plp': {},
            'productView': {},
            'gridView': {},
            'refinementsContainer': {},
            'refinement': {},
            'sorting': {},
            'productList': {},
            'productTile': {},
            'searchControls': {},
            'quickShop': {},
            'notifyMe': {}
        }
    },
    'wishlist': {},
    'storelocator': {},
    'checkoutlogin': {
        'components': {
            'passwordResetForm': {},
            'loginWithLoyaltyCheck': {}
        }
    }
};

module.exports = {
    'configuration': configuration,
    'references': references
};
