'use strict';

/* eslint-disable */

var gtmUtils = require('./utils'),
    utils = require('./../../util'),
    mappings = require('./mappings.json'),
    pageDataCollectors = require('./page-data-collectors');

function setActionPosition(eTarget, thisVar, productDetails) {
    var categoryName = $(thisVar).closest('.js-menu-slot').length > 0 ? 'header' : null;
    var actionFieldList = productDetails.hasOwnProperty('list') ? productDetails.list : '';
    var recommendationsCarouselData = $(eTarget).parents('.recommendations-carousel');
    var recommendationsCarouselTitle = recommendationsCarouselData.data("display-title");
    var recentlyViewedDiv = $(eTarget).parents('.recently-viewed-carousel');
    var productPosition = $(thisVar).parents('li').index() + 1;
    let hrefVal = $(thisVar).attr('href');
    if (recentlyViewedDiv.length > 0) {
        actionFieldList = recentlyViewedDiv.find('.recently-viewed-carousel__title')[0].innerHTML;
    } else if(pageContext.type.toUpperCase() === 'search'.toUpperCase()) {
        actionFieldList = 'Search';
    } else if($(eTarget).parents('.c-mini-cart__product-item').length > 0) {
        actionFieldList = 'minibag';
        categoryName = 'header_bag';
        productPosition = parseInt($(thisVar).parents('.js-line-item-row').data('productDetails').position);
    } else if($(eTarget).parents('#cart-table').length > 0) {
        actionFieldList = 'cart';
        categoryName = 'funnel_cart';
        productPosition = parseInt($(thisVar).parents('.js-line-item-row').data('productDetails').position);
    } else if(recommendationsCarouselData.length > 0 && recommendationsCarouselTitle !== undefined){
        actionFieldList = recommendationsCarouselData.data("display-title");
        productPosition = parseInt($(thisVar).parents('.js-product-impression').data('json-config').productPosition);
    }
    const now = new Date();
    const ttl = 180000; // 3mins
    const itemObj = {
        actionFieldList: actionFieldList,
        productPosition: productPosition,
        hrefVal: hrefVal,
        expiry: now.getTime() + ttl
    };
    var productActionPositon = JSON.parse(localStorage.getItem('productActionPositon') || '[]');
    if (productActionPositon.length > 0) {
        productActionPositon = $.grep(productActionPositon, (item) => {
            if (now.getTime() > item.expiry || item.hrefVal == hrefVal) {
                return false;
            } else {
                return true;
            }
        });
    }
    productActionPositon.push(itemObj);
    window.sessionStorage.actionFieldList = actionFieldList;
    window.sessionStorage.productPosition = productPosition;
    localStorage.setItem('productActionPositon', JSON.stringify(productActionPositon));

    var selectedView = $('.js-cmp-gridView');
    var viewType = 'not apply';     
    if(selectedView.data('cmp') == 'gridView' && actionFieldList !== 'minibag' && pageContext.type && (pageContext.type==='plp' || pageContext.type.toUpperCase() === 'search'.toUpperCase())){
        viewType = selectedView.data('json-config').preSelectedViewMode;
    }
    window.sessionStorage.gridViewType = viewType;

    return [actionFieldList, categoryName, productPosition];
}

function removeFromDataLayer(isCartPage,isTriggeredFromCheckout,productLabel,listValue,productDetails,analyticsData,productDet){
    var analyticsEvent = {
        'event': 'analyticsEvent',
        'eventData': {
            'category': isCartPage || isTriggeredFromCheckout ? 'funnel_cart' : 'header_bag',
            'action': 'remove_product',
            'label': productLabel
        },
        'customData': {
            'user': pageContext.user,
            'category': analyticsData.categoryData,
            'extraData': analyticsData.extraData
        },
        'ecommerce': {
            'event': 'removeFromCart',
            'currencyCode': analyticsData.page.currencyCode,
            'remove': {
                'actionField': {
                    'list': productDet.list
                }, 
                'products': [productDet]
            }
        }
    }
    if (SitePreferences.ATTRAQT_ENABLED) {
        var attraqtdata = {
            'action':'remove-from-cart',
            'target': {
                'product': productLabel
            }
        }
        analyticsEvent.attraqtdata = attraqtdata;
    }
    dataLayer.push(analyticsEvent);   
}

//removeFromDataLayer in UA - removeItemsFromDataLayer in GA4                        
function removeItemsFromDataLayer(productDetails,analyticsData,product, $form, productsToRemove){
    var selectedView = $('.js-cmp-gridView'),
        selectedViewValue = 'not apply', item_concatcategory = 'not apply', categoryArray,discountDifference = '', discountPercentage = '', fullPrice = '',
        list='', imageType='';     
        if(selectedView.data('cmp') == 'gridView'){
                selectedViewValue = selectedView.data('json-config').preSelectedViewMode;
        }
            
    //imageType is model/product
    var imageSrcUrl = $('.js-zoom img').data('src') || $form.data('product-details').imageUrl;
    if(imageSrcUrl.includes('FL') ){
        imageType = "product";
    } else if(imageSrcUrl.includes('MO')){
        imageType = "model";
    }
          
    if (productDetails) {
        discountDifference = parseFloat(productDetails.standardPrice - productDetails.price).toFixed(2).replace(/[.,]00/, "");
        discountPercentage = productDetails.discount;
        fullPrice = productDetails.standardPrice;
        list=productDetails.list;
        if(productDetails.category){
            item_concatcategory = productDetails.category;
            categoryArray = item_concatcategory.split("/");
        }
        
        if(pageContext.type.toUpperCase() === 'checkout'.toUpperCase() && $('.js-edit-cart').length > 0){
            product.quantity = $('.js-edit-cart').data('trigger-initial-qty') || 1;
        }
        var productToRemove = {
            'item_name' : productDetails.name,
            'item_id' : productDetails.id,
            'price': productDetails.price,
            'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
            'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
            'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
            'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
            'item_concatcategory' : item_concatcategory,
            'item_variant': productDetails.variant,
            'item_wash': (productDetails.wash && productDetails.wash!='undefined' ? productDetails.wash : 'not apply'),
            'item_variation_group': (productDetails.wash && productDetails.wash!='undefined' ? (productDetails.id + productDetails.wash) : (productDetails.id + productDetails.variant)),
            'item_size': (productDetails.dimension15 ? productDetails.dimension15 : 'not apply'),
            'item_length': ((productDetails.length == 'undefined') ? 'not apply' : (productDetails.length == undefined ? 'not apply' : productDetails.length)),
            'item_list_name': list,
            'item_list_id': list,
            'index': productDetails.position,
            'quantity': product.quantity || 1,
            'discount' : (discountDifference ? discountDifference : '0'),
            'item_image_type': imageType,
            'item_grid_type': productDetails.item_grid_type,
            'item_full_price': ((discountDifference == 0) ? 'yes' : 'no'),
            'item_discount_percentage': (discountPercentage ? discountPercentage : '0') 
        }
        //dataLayer.push(analyticsEvent);  
        productsToRemove.push(productToRemove); 
        return productsToRemove;
    }
}

//iterate items for basket and push in array
function setProductsForDataLayer(pageBasket, productsPurchase){
    var item_concatcategory = 'not apply', categoryArray = [];
    var imageType = '', discountDifference = '', discountPercentage = '', fullPrice = '';
    var selectedView = $('.js-cmp-gridView');
    var selectedViewValue = 'not apply';     
    if(selectedView.data('cmp') == 'gridView'){
        selectedViewValue = selectedView.data('json-config').preSelectedViewMode;
    }

    var productsDetails = pageBasket;
        productsPurchase = [];
        for (var i = 0; i < productsDetails.length; i++) {
            var $this = productsDetails[i]
            if ($this) {
                discountDifference = parseFloat($this.standardPrice - $this.price).toFixed(2).replace(/[.,]00/, "");
                discountPercentage = $this.discount;
                fullPrice = $this.standardPrice
            }
        
            if ($this.category) {
                item_concatcategory = $this.category;
                categoryArray = item_concatcategory.split("/");
            }

            var imageSrcUrl = $this.imageUrl;
            if(imageSrcUrl && imageSrcUrl.includes('FL')){
                imageType = "product";
            } else if(imageSrcUrl && imageSrcUrl.includes('MO')){
                imageType = "model";
            }
            productsPurchase.push({
                'item_id': $this.id,
                'item_name': $this.name,
                'price': $this.price,
                'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                'item_concatcategory' : item_concatcategory,
                'item_variant': $this.variant,
                'item_wash': ($this.wash && $this.wash!='undefined' ? $this.wash : 'not apply'),
                'item_variation_group': ($this.wash && $this.wash!='undefined' ? ($this.id + $this.wash) : ($this.id + $this.variant) ),
                'item_size': ($this.dimension15 ? $this.dimension15 : 'not apply'),
                'item_length': ($this.length ? $this.length : 'not apply'),
                'item_list_name': $this.list,
                'item_list_id': $this.list,
                'index': $this.position,
                'quantity': $this.quantity,
                'discount': (discountDifference ? discountDifference : '0'),
                'item_image_type': imageType,
                'item_grid_type': $this.item_grid_type,
                'item_full_price': ((discountDifference == 0) ? 'yes' : 'no'),
                'item_discount_percentage': (discountPercentage ? discountPercentage : '0')
            });
        }
        
        return productsPurchase;
}

//iterate errors to display as "errorLabel1-errorMessage1 / errorLabel2-errorMessage2" 
//eg: "First Name - This field is required. / Last Name - This field is required. / Confirm Password - Required field / You must accept Privacy Policy and Terms and Conditions
function iterateErrors(errors, errorsCache, errorflag) {
    var errorMessagesList = '',eLabel='';
    var errorElement='', errorLabel='',isCheckbox='',isDropdown='',isInputLabel='';
   for (var i = 0; i < errors.length; i++) {
        errorElement = errors[i].element;
        if (errorsCache[errors[i].element.id]) {
            errorflag = true;
            return [errorMessagesList, errorflag];
        }
        errorsCache[errors[i].element.id] = errors[i].message;
        eLabel = $(errorElement.labels).find('.form-row_value').text();
        var adyenNameLabel = $(errorElement.labels).find('.adyen-checkout__label__text');
        if(adyenNameLabel.length > 0){
            eLabel = adyenNameLabel.text();
        }
        isCheckbox = $(errors[i].element)[0].classList.contains('input-checkbox');
        isDropdown = $(errors[i].element)[0].classList.contains('input-select');
        isInputLabel = $(errors[i].element).parents('.b-select_container').siblings().hasClass('js-input-label');
        if(isDropdown && !isInputLabel){
            errorLabel = $(errorElement).parent().siblings('.selectric').find('.label').text()
            errorMessagesList = errorMessagesList + ((i!=0) ? ' / ' : '') + errorLabel + '-' + errors[i].message;
        } else if (!isCheckbox) {
            errorLabel = eLabel;
            errorMessagesList = errorMessagesList + ((i!=0) ? ' / ' : '') + errorLabel + '-' + errors[i].message;
        } else {
            errorLabel = 'Checkbox';
            errorMessagesList = errorMessagesList + ((i!=0) ? ' / ' : '') + errorLabel + '-' + errors[i].message;
        } 
        (function(el) {
            setTimeout(function () {
                delete errorsCache[el];
            }, 500);
        })(errors[i].element.id);
    }
    return [errorMessagesList,errorflag];
}

module.exports = {
    'formErrors': function (analyticsData = {}) {
        var errorsCache = {};
        $('form').on('invalid-form.validate', function (event, $validator) {
            var errors = $validator.errorList;

            if (errors) {
                var errorMessagesList = [];
                for (var i = 0; i < errors.length; i++) {
                    if (errorsCache[errors[i].element.id]) {
                        return;
                    }
                    errorsCache[errors[i].element.id] = errors[i].message;
                    errorMessagesList.push(errors[i].message);

                    (function(el) {
                        setTimeout(function () {
                            delete errorsCache[el];
                        }, 500);
                    })(errors[i].element.id);
                }
                dataLayer.push({
                    'event': 'analyticsEvent',
                    'eventData': {
                        'category': analyticsData.checkout && analyticsData.checkout.step === "2" ? 'funnel_delivery' : 'funnel_ payment',
                        'action': 'error_form',
                        'label': errorMessagesList
                    }
                });
            }
        });
    },

    'coupon': function () {
        $(document).on('coupon.added', function (event, data) {
            var analyticsData = $(data).find('#coupon-code').data('analytics');
            if (analyticsData) {
                dataLayer.push({
                    'event': 'analyticsEvent',
                    'eventData': {
                        'category': 'funnel_cart',
                        'action': 'apply_code',
                        'label': analyticsData.couponCode + '-' + analyticsData.couponStatus
                    }
                });
            }
        });
    },

    'login': function () {
        if (utils.getParameterValueFromUrl('loginSuccess', window.location.search)) {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'funnel_account',
                    'action': 'login_ok',
                    'label': undefined
                }
            });
        }
    },

    'register': function () {
        if (utils.getParameterValueFromUrl('registerSuccess', window.location.search)) {
            var preferences = utils.jsonParse(pageContext.user.subscriptionPreferences);
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'register',
                    'action': 'create_account_ok',
                    'label': (Object.keys(preferences).filter(i => Number(preferences[i]) > 0 ?  i : 0)).join('|')
                }
            });
        }
    },

    'shippingMethodChange': function () {
        $(document).on('shippingmethod.selected', function () {
            dataLayer.push({
                'event': 'checkoutOption',
                'ecommerce': {
                    'checkout_option': {
                        'actionField': {
                            'step': 1,
                            'option': $('#shipping-method-list input:checked').val()
                        }
                    }
                }
            });
        });
    },

    'shippingSubmit': function (analyticsData = {}) {
        $('.checkout-shipping').on('submit', function () {
            var checkoutData = gtmUtils.getCheckoutData(analyticsData, 1);

            dataLayer.push(checkoutData);
        });
    },

    'billingSubmit': function (analyticsData = {}) {
        $('.checkout-billing').on('submit', function () {
            var checkoutData = gtmUtils.getCheckoutData(analyticsData, 2);

            dataLayer.push(checkoutData);
        });
    },

    'addToCart': function (analyticsData = {}) {
        analyticsData.page = analyticsData.page || {};
        analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';

        $(document).on('product.addedToCart', function (event, $form, qty) {
            if ($form) {
                var productDetails = $form.data('productDetails'),
                    isMinicart = $form.hasClass('c-mini-cart__product-item-row') || $form.parents('.product-edit-modal').hasClass('miniBagTriggered'),
                    isCartPage = $form.hasClass('c-products-table__item') || $form.parents('.product-edit-modal').hasClass('cartTriggered'),
                    isEditPopup = $form.parents('.product-edit-modal').length > 0 ? true : false,
                    isTriggeredFromCheckout = $form.parents('.product-edit-modal').hasClass('triggeredFromCheckout'),
                    actionFieldList = productDetails && productDetails.hasOwnProperty('list') ? productDetails.list : '';

                if (isEditPopup) {
                    var selectedQuantity = parseInt($form.siblings('.quantity-select').find('.js-item-quantity-field').val() || $form.find('#quantity').val());
                    var originalQTY = $form.parents('.js-edit-cart').data('trigger-initial-qty');
                    var changedQTY = selectedQuantity;
                    var isVariationChanged = $form.parents('.js-edit-cart').data('variation-changed');
                    if(!isVariationChanged){//if variation changed
                        if(selectedQuantity != originalQTY){
                            changedQTY = selectedQuantity - originalQTY;
                        }
                    }
                }


                actionFieldList && delete productDetails.list;
                var eventDataCategory = 'product';
                if (isCartPage) {
                    eventDataCategory = 'funnel_cart';
                    actionFieldList = "cart"
                } else if (isMinicart && !isTriggeredFromCheckout) {
                    eventDataCategory = 'header_bag';
                    actionFieldList = "minibag"
                } else if(isTriggeredFromCheckout) {
                    eventDataCategory = 'funnel_cart';
                    actionFieldList = "ordersummary"
                }
                if(window.sessionStorage.actionFieldList){
                    actionFieldList = window.sessionStorage.actionFieldList;
                }

                if (productDetails) {
                    productDetails.quantity = changedQTY || qty || 1 ;
                    if(window.sessionStorage.productPosition){
                        productDetails.position = window.sessionStorage.productPosition;
                    }
                    productDetails.list = actionFieldList;
                    if (isCartPage) {
                        productDetails.list = 'cart';
                        if(isEditPopup === false) {
                            productDetails.position = $('.c-products-table .c-products-table__item').index($(event.currentTarget.activeElement).parents('.c-products-table__item')) + 1;
                        } else {
                            productDetails.position = $form.parents('.js-edit-cart').attr('data-trigger-pos');
                        }
                        productDetails.item_grid_type = 'not apply';

                    } else if (isMinicart && !isTriggeredFromCheckout) {
                        productDetails.list = 'minibag';
                        if(isEditPopup === true) {
                            productDetails.position = $form.parents('.js-edit-cart').attr('data-trigger-pos');
                        }
                        productDetails.item_grid_type = 'not apply';
                    } else if(isTriggeredFromCheckout) {
                        productDetails.list = 'ordersummary';
                        productDetails.position = $form.parents('.js-edit-cart').attr('data-trigger-pos');
                        productDetails.item_grid_type = 'not apply';
                    }
                    window.sessionStorage.removeItem('actionFieldList');
                    window.sessionStorage.removeItem('productPosition');
                    var productLabelPDP = $form.find('#product-addtocart #pid').val();
                    var productLabelMinicart = $form.data('pid');
                    if(isEditPopup) {
                        productLabelMinicart = $form.find('#pid').val();
                    }
                    var analyticsEvent = {
                        'event': 'analyticsEvent',
                        'eventData': {
                            'category': eventDataCategory,
                            'action': 'add_to_bag',
                            'label': pageContext.currentPage === 'product' && !isMinicart ? productLabelPDP : productLabelMinicart
                        },
                        'customData': {
                            'user': pageContext.user,
                            'category': analyticsData.categoryData,
                            'extraData': analyticsData.extraData
                        },
                        'ecommerce': {
                            'currencyCode': analyticsData.page.currencyCode,
                            'add': {
                                'actionField': {
                                    'list': actionFieldList
                                },
                                'products': [productDetails]
                            }
                        }
                    }
                    if(SitePreferences.ATTRAQT_ENABLED){
                        var sessionIdVal = $('#wrapper').find('#sessionId');
                        var sessionIdValue;
                        if (sessionIdVal.length > 0) {
                            sessionIdValue = sessionIdVal.val();
                        }
                        var sourceIdValue = '';
                        var sourceIdVal = $('#wrapper').find('#sourceId')
                        if(sourceIdVal.length > 0){
                            sourceIdValue = sourceIdVal.val();
                        }
                        var attraqtdata = {
                            'action':'add-to-cart',
                            'target': {
                                'product': productDetails.id + productDetails.variant
                            },
                            'metadata': {
                                'quantity': productDetails.quantity,
                                'locale' : pageContext.currentLocale
                            },
                            'sourceId': sourceIdValue,
                            'sessionId': sessionIdValue
                        } 
                        analyticsEvent.attraqtdata = attraqtdata;
                    }
                    dataLayer.push(analyticsEvent);
                }
            }
        });
    },

    'quickShopAddToCart': function (analyticsData = {}) {
        analyticsData.page = analyticsData.page || {};
        analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';

        $(document).on('quickShop.addedToCart', function (event, data, $element) {
            var $el = $element.closest('.product-tile');
            var eventDataCategory = 'category';
            var eventDataProductID = data.pid || undefined;
            var productDetails = $element.data('productDetails') || {};
            var customData = $el.data('productCd') || {};
            var actionFieldList = productDetails.hasOwnProperty('list') ? productDetails.list : '';
            if(pageContext.type.toUpperCase() === 'search'.toUpperCase()) {
                actionFieldList = 'Search';
                eventDataCategory = 'Search';
            } 
            actionFieldList && delete productDetails.list;
           
            var basketObject = pageContext.analytics.basket.ecommerce;
            productDetails.dimension15 = basketObject[basketObject.length-1].dimension15;
            productDetails.length = basketObject[basketObject.length-1].length;
            productDetails.quantity = $element.find('#Quantity').val() || 1;
            productDetails.list = actionFieldList;

            var analyticsEvent = {
                'event': 'analyticsEvent',
                'eventData': {
                    'category': eventDataCategory,
                    'action': 'add_to_bag',
                    'label': eventDataProductID
                },
                'category': {
                    'season': customData.season || undefined,
                    'gender': productDetails.category || undefined
                },
                'customData': {
                    'user': pageContext.user,
                    'category': analyticsData.categoryData,
                    'extraData': {
                        'imageType': customData.imageType || undefined,
                        'productVideo': customData.productVideo || undefined,
                        'badgeText': customData.badgeText || undefined
                    }
                },
                'ecommerce': {
                    'currencyCode': analyticsData.page.currencyCode,
                    'add': {
                        'actionField': {
                            'list': actionFieldList,
                        },
                        'products': [productDetails]
                    }
                }
            }
            if (SitePreferences.ATTRAQT_ENABLED) {
                var sessionIdVal = $('#wrapper').find('#sessionId');
                var sessionIdValue = sessionIdVal.val(); 
                var sourceIdValue = '';                
                var sourceIdVal = $('#wrapper').find('#sourceId');
                    if(sourceIdVal.length > 0){
                        sourceIdValue = sourceIdVal.val(); 
                    }
                

                var attraqtdata = {
                    'action': 'add-to-cart',
                    'target': {
                        'product': productDetails.id + productDetails.variant
                    },
                    'metadata': {
                        'quantity': 1,//productDetails.quantity
                        'locale': pageContext.currentLocale
                    },
                    'sourceId': sourceIdValue,
                    'sessionId': sessionIdValue
                }
                analyticsEvent.attraqtdata = attraqtdata;
            }
            dataLayer.push(analyticsEvent);
        });
    },

    'quickShopAddToCartCarousel': function (analyticsData = {}) { 
        analyticsData.page = analyticsData.page || {};
        analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';

        $(document).on('quickShop.addedToCartCarousel', function (event, data, $element) {
            var $el = $element.closest('.product-tile');
            var eventDataCategory = 'carousel';
            var eventDataProductID = data.pid || undefined;
            var productDetails = $element.data('productDetails') || {};
            var customData = $el.data('productCd') || {};

            var jsProductImpression = $el.closest('.product-tile.js-product-impression');
            var jsProductImpressionProductDetails = jsProductImpression.data('json-config');
            productDetails.position = jsProductImpressionProductDetails.productPosition;

            var list = productDetails.list;
            var recommendationsCarouselData = $el.parents('.recommendations-carousel');
            var recommendationsCarouselTitle = recommendationsCarouselData.data('display-title');
            var recentlyViewedDiv = $el.parents('.recently-viewed-carousel');

            if (recentlyViewedDiv.length > 0) {
                list = recentlyViewedDiv.find('.recently-viewed-carousel__title')[0].innerHTML;
            } else if (recommendationsCarouselData.length > 0 && recommendationsCarouselTitle !== undefined) {
                list = recommendationsCarouselData.data('display-title');
            }
            var actionFieldList = list;
            
            var basketObject = pageContext.analytics.basket.ecommerce;
            productDetails.dimension15 = basketObject[basketObject.length-1].dimension15;
            productDetails.length = basketObject[basketObject.length-1].length;
            productDetails.quantity = $element.find('#Quantity').val() || 1;
            productDetails.list = list;

            var analyticsEvent = {
                'event': 'analyticsEvent',
                'eventData': {
                    'category': eventDataCategory,
                    'action': 'add_to_bag',
                    'label': eventDataProductID
                },
                'category': {
                    'season': customData.season || undefined,
                    'gender': productDetails.category || undefined
                },
                'customData': {
                    'user': pageContext.user,
                    'category': analyticsData.categoryData,
                    'extraData': {
                        'imageType': customData.imageType || undefined,
                        'productVideo': customData.productVideo || undefined,
                        'badgeText': customData.badgeText || undefined
                    }
                },
                'ecommerce': {
                    'currencyCode': analyticsData.page.currencyCode,
                    'add': {
                        'actionField': {
                            'list': actionFieldList,
                        },
                        'products': [productDetails]
                    }
                }
            }
            if (SitePreferences.ATTRAQT_ENABLED) {
                var sessionIdVal = $('#wrapper').find('#sessionId');
                var sessionIdValue = sessionIdVal.val(); 
                var sourceIdValue = '';
                var sourceIdVal = $('#wrapper').find('#sourceId');
                    if(sourceIdVal.length > 0){
                        sourceIdValue = sourceIdVal.val(); 
                    }
                
                var attraqtdata = {
                    'action': 'add-to-cart',
                    'target': {
                        'product': productDetails.id + productDetails.variant
                    },
                    'metadata': {
                        'quantity': 1,//productDetails.quantity
                        'locale': pageContext.currentLocale
                    },
                    'sourceId': sourceIdValue,
                    'sessionId': sessionIdValue
                }
                analyticsEvent.attraqtdata = attraqtdata;
            }
            dataLayer.push(analyticsEvent);
        });
    },

    'quickView': function (analyticsData = {}) {
        analyticsData.page = analyticsData.page || {};
        analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';

        $(document).on('quickview.opened', function (event, $container) {
            if (!$container) {
                return;
            }

            var productDetails = $container.find('[data-product-details]').data('productDetails');
            if (productDetails) {
                dataLayer.push({
                    'product': productDetails
                });
            }

            dataLayer.push({
                'event': 'productView',
                'ecommerce': {
                    'currencyCode': analyticsData.page.currencyCode,
                    'detail': {
                        'actionField': {
                            'list': 'product quick view'
                        },
                        'products': [productDetails]
                    }
                }
            });
        });
    },

    'promoClick': function (analyticsData = {}) {
        $(document).on('click', '.js-promo-impression', function () {
            var promoDetails = $(this).data('promo-details');

            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': analyticsData.page ? analyticsData.page.pageCategory : '',
                    'action': 'promoClick',
                    'label': ''
                },
                'customData': {
                    'user': pageContext.user,
                    'category': analyticsData.categoryData,
                    'extraData': analyticsData.extraData
                },
                'ecommerce': {
                    'promoClick': {
                        'promotions': [{
                            'id': promoDetails.id,
                            'name': promoDetails.name,
                            'creative': promoDetails.creative,
                            'position': promoDetails.position
                        }]
                    }
                }
            });
        });
    },

    'removeFromCart': function (analyticsData = {}) {
        analyticsData.page = analyticsData.page || {};
        analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';

        $(document).on('product.removeFromCart', function (event, $form, qty) {
            var productDetails = $form.data('productDetails');
            var isCartPage = $form.hasClass('c-products-table__item');
            var isTriggeredFromCheckout = $(event.currentTarget.activeElement).parents('.product-edit-modal').hasClass('triggeredFromCheckout');
            var listValue = productDetails && productDetails.hasOwnProperty('list') ? productDetails.list : '';
            var productLabel = $form.data('pid');
            var editPopupVariationChanged = $form.parents('.pt_cart').siblings('.ui-dialog').find('.js-edit-cart').data('variation-changed');
            analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';
            productDetails.quantity = parseInt(qty || $form.find('input.js-item-quantity-field').val() || 1);

            if (productDetails) {
                var totalqtytoremove,product,removedqty=0,remainingqtytoremove=0,isremainingzero=false;
                var i,basketObj;
                if(pageContext.type.toUpperCase() === 'plp'.toUpperCase() && window.sessionStorage.ecommerce){
                    basketObj = JSON.parse(window.sessionStorage.ecommerce);
                } else {
                    basketObj = pageContext.analytics.basket.ecommerce;
                }
                totalqtytoremove = qty;
                productDetails = $form.data('productDetails');
                for(i=basketObj.length-1;i>=0;i--){
                    product = basketObj[i];
                    if(remainingqtytoremove!=totalqtytoremove && !isremainingzero){
                        if(product.id == productDetails.id && product.dimension15 == productDetails.dimension15 && product.variant == productDetails.variant && (product.length == productDetails.length || String(product.length) == String(productDetails.length))){
                            if(product.quantity > productDetails.quantity){
                                product.quantity = productDetails.quantity;
                                if(editPopupVariationChanged || (product.quantity-remainingqtytoremove)==0){
                                    removedqty = removedqty+productDetails.quantity;
                                } else {
                                    if(remainingqtytoremove!=0 && product.quantity>=remainingqtytoremove){
                                        product.quantity = remainingqtytoremove;
                                    }
                                    removedqty = removedqty + product.quantity;
                                }
                            } else {
                                if(editPopupVariationChanged || (product.quantity-remainingqtytoremove)==0){
                                    removedqty = removedqty+product.quantity;
                                } else {
                                    if(remainingqtytoremove!=0 && product.quantity>=remainingqtytoremove){
                                        product.quantity = remainingqtytoremove;
                                    }
                                    removedqty = removedqty + product.quantity;
                                } 
                            }
                            remainingqtytoremove = totalqtytoremove - removedqty;
                            removeFromDataLayer(isCartPage,isTriggeredFromCheckout,productLabel,listValue,productDetails,analyticsData,product);
                            if(remainingqtytoremove<=0){
                                isremainingzero=true;
                            }
                        } else {
                            continue;
                        }
                    }
                } 
                           
            }
        });
    },

    'productClick': function (analyticsData = {}) {
        $(document).on('click', '.js-product-impression a:not(.swatch, .js-more-colors-link), .js-product-nav, .video-content .video-content-anchor, .link-block', function (e) {
            // Prevent sending "click_product" event when user clicks on slider controls
            e.stopPropagation();
            if ($(e.target).hasClass('js-slider-button') || $(e.target).parent().parent().hasClass('js-slider-button') || $(e.target).hasClass('c-quick-shop__bag-icon') || $(e.target).hasClass('c-quick-shop__close-icon')) {
                return;
            }

            var productDetails = $(this).data('productDetails');
            if (!productDetails) {
                productDetails = $(this).closest('.js-product-impression, .c-mini-cart__product-item-row, .c-products-table__item').data('productDetails');
            }

            var customData = $(this).data('productCd');
            if (!customData) {
                customData = $(this).closest('.js-product-impression').data('productCd') || {};
            }

            if (productDetails) {
                var action = 'click_product';
                var label = productDetails.name;
                var [actionFieldList, categoryName, productPosition] = setActionPosition(e.target, this, productDetails);

                if(!categoryName) {
                    if (analyticsData.page && mappings.pageCategory[analyticsData.page.pageCategory]) {
                        categoryName = mappings.pageCategory[analyticsData.page.pageCategory];
                    } else if (pageContext.type && (pageContext.type==='plp' || pageContext.type.toUpperCase() === 'search'.toUpperCase())) {
                        categoryName = mappings.pageCategory['plp'];
                    }
                }

                if ($(this).hasClass('product-previous')) {
                    label = 'previous';
                } else if ($(this).hasClass('product-next')) {
                    label = 'next';
                }

                var dataAnalytics = $(this).closest('[data-analytics]').data('analytics');
                if (dataAnalytics) {
                    action = dataAnalytics.action;
                    var postion = $(this).parents('li').index();
                    label = postion + '-' + productDetails.id;
                }

                var selectedView = $('.js-cmp-gridView');
                var viewType = 'not apply';     
                if(selectedView.data('cmp') == 'gridView' && actionFieldList !== 'minibag' && pageContext.type && (pageContext.type==='plp' || pageContext.type.toUpperCase() === 'search'.toUpperCase())){
                    viewType = selectedView.data('json-config').preSelectedViewMode;
                }
                var analyticsEvent = {
                    'event': 'analyticsEvent',
                    'eventData': {
                        'category': categoryName,
                        'action': action,
                        'label': label
                    },
                    'customData': {
                        'user': pageContext.user,
                        'category': analyticsData.categoryData,
                        'extraData': {
                            'imageType': customData.imageType || undefined
                        }
                    },
                    'ecommerce': {
                        'currencyCode': analyticsData.page.currencyCode,
                        'click': {
                            'actionField': {
                                'list': actionFieldList
                            },
                            'products': [{
                                'name': productDetails.name,
                                'id': productDetails.id,
                                'price': productDetails.price,
                                'brand': productDetails.brand,
                                'category': productDetails.category,
                                'variant': productDetails.variant,
                                'position': productPosition,
                                'list': actionFieldList,
                                'item_grid_type': viewType
                            }]
                        }
                    }
                } 
            }
            if (SitePreferences.ATTRAQT_ENABLED) {
                var isMinicart = $(this).parents().hasClass('c-mini-cart__product-item-row');
                var isCartPage = $(this).parents().hasClass('c-products-table__item');
                var isOrderSummary = $(this).parents().hasClass('order-summary-data');
                if (!isMinicart && !isCartPage && !isOrderSummary){
                    var sessionIdVal = $('#wrapper').find('#sessionId');
                    var sessionIdValue;
                    sessionIdValue = sessionIdVal.val();

                    var sourceIdValue='';
                    var sourceIdVal = $('#wrapper').find('#sourceId');
                        if(sourceIdVal.length > 0){
                            sourceIdValue = sourceIdVal.val(); 
                        }

                    
                    var attraqtdata = {
                        'action':'click',
                        'target': {
                        },
                        'metadata': {
                            'locale' : pageContext.currentLocale
                        },
                        'sourceId': sourceIdValue,
                        'sessionId': sessionIdValue
                    }
                    var campaignIdValue = $(this).data('campaign-id');
                    var isVideoAttraqt = $(this).hasClass('video-content-anchor');
                    var isPLPBannerAttraqt = $(this).hasClass('link-block');
                    if (isVideoAttraqt || isPLPBannerAttraqt) {
                        var analyticsEvent = {
                            'event': 'analyticsEvent',
                        }    
                        var productUrl = $(this).attr('href');
                        var urlSubs = productUrl.substring(productUrl.lastIndexOf('/') + 1);
                        var subStr = urlSubs.substring(0, urlSubs.lastIndexOf('.'));
                        var subStr1 = subStr.substring(subStr.length, subStr.lastIndexOf('-'));
                        var productIdAttraqt = subStr1.substring(subStr1.lastIndexOf('-') + 1);
                        attraqtdata.target.product = productIdAttraqt;
                    } else {   
                        attraqtdata.target.product = productDetails.variationgroupid; 
                    }
                    if(campaignIdValue){
                        attraqtdata.target.campaign = campaignIdValue;
                    }
                    analyticsEvent.attraqtdata = attraqtdata;
                }
            }
            dataLayer.push(analyticsEvent);     
        });
    },

    //for attraqt filter
    'filterClick': function(){
        $(document).on('click', '.js-refinement-subitem a', function (e) {
            if (SitePreferences.ATTRAQT_ENABLED) {
                var facetId = $(this).closest('.c-refinements__item').find('.c-refinements__item-heading-info').data('facet-id');
                var facetValue = $(this).data('refinement-value');
                var sourceIdVal = $(this).parents('#wrapper').find('#sourceId');
                var sessionIdVal = $('#wrapper').find('#sessionId');
                var sourceIdValue='', sessionIdValue;
                var labelClick = sourceIdVal.context ? sourceIdVal.context.innerText : '';
                sessionIdValue = sessionIdVal.val();  
                if(sourceIdVal.length > 0){
                    sourceIdValue = sourceIdVal.val();
                }

                var filterPage,filterValue, label='';
                var filterLabel =  $(this).data('refinement-value');
                if ($(this).parents().hasClass('top-refinement-bar')){
                    var filterVal = $(this).parents('.primary-content').find('.c-category-header__text');
                    if(filterVal.length > 0){
                        filterPage = filterVal[0].innerHTML;
                        label = filterPage.trim()+"-"+filterLabel;
                    }
                } else{
                    var filterFacet = $(this).closest('.c-refinements__item').find('.c-refinements__item-heading-info');
                    if(filterFacet.length > 0){
                        filterValue = $(this).closest('.c-refinements__item').find('.c-refinements__item-heading-info')[0].innerHTML;
                        label = filterValue.trim()+"-"+filterLabel;
                    }
                }
                var analyticsEvent = {
                    'event': 'analyticsEvent',
                    'eventData': {
                        'category': 'category',
                        'action': 'filter',
                        'label': label
                    }
                }
                let hrefValue = $(this).attr('href'); // Get the href value
                if (!hrefValue.includes('?')){
                    dataLayer.push({
                        'event': 'select_category',
                        'eventData': {
                          'event_category': 'bubbles',
                          'event_label': labelClick
                        }
                    });
                }
               
                var attraqtdata = {
                    'action': 'click',
                    'target': {
                        'facet': facetId,
                        [facetId]: facetValue
                    },
                    'metadata': {
                        'locale': pageContext.currentLocale
                    },
                    'sourceId': sourceIdValue,
                    'sessionId': sessionIdValue
                }
                analyticsEvent.attraqtdata = attraqtdata;
                dataLayer.push(analyticsEvent);                
            } else {
                let spanHtml = $(this).find('span').html();
                let hrefValue = $(this).attr('href'); // Get the href value
                if (!hrefValue.includes('?')){
                    dataLayer.push({
                        'event': 'select_category',
                        'eventData': {
                          'event_category': 'bubbles',
                          'event_label': spanHtml
                        }
                    });
                }
            }
        });
    },

    'impressions': function (analyticsData = {}) {
        $(document).on('window.scroll', function () {
            pageDataCollectors.productImpression(analyticsData);
            pageDataCollectors.promoImpression(analyticsData);
        });

        $(document).on('click', '.jcarousel-nav', function () {
            setTimeout(function () {
                pageDataCollectors.productImpression(analyticsData);
            }, 600);
        });

        $(document).on('swiperslideChangeTransitionEnd', function() {
            setTimeout(function () {
                pageDataCollectors.productImpression(analyticsData);
            }, 300);
        });
    },

    'menuClick': function () {
        $(document).on('click', 'li.js-menu-tab-content > .js-menu-link, li.js-nav-system-subnav-item > .js-nav-system-subnav-link, li.submenu-item > .submenu-link, div.submenu-links > .submenu-links-content > .submenu-links-column > a.submenu-links-title', function () {
            var categoryID = $(this).data('categoryId') || '';
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'header',
                    'action': 'click_menu',
                    'label': categoryID
                }
            });
        });
    },

    'menuBannerClick': function () {
        $(document).on('click', '.js-menu-slot:not(.product-listing-featured) a', function () {
            var path = $(this)[0].pathname;
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'header',
                    'action': 'click_banner',
                    'label': path
                }
            });
        });
    },

    'searchCategoryClick': function () {
        $(document).on('click', '.js-search-suggestion__list > li', function () {
            var catName = $(this).children('a').text();
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'Search',
                    'action': 'click_category',
                    'label': catName
                }
            });
        });
    },

    'searchTermClick': function () {
        $(document).on('click', 'a.js-search-suggestion__phrase', function () {
            var term = $(this).children('span').text();
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'Search',
                    'action': 'click_term',
                    'label': term
                }
            });
        });
    },

    'searchProductClick': function (analyticsData = {}) {
        $(document).on('click', '.js-search-suggestion__product-link', function () {
            var productName;
            var productDetails;
            if($(this).hasClass('c-product-tile__name') || $(this).children().hasClass('c-product-tile__name')){
                productName = $(this).text().trim();
                productDetails = $(this).parent().siblings().find('.js-product-impression').data('product-details');
            }else{
                productName = $(this).siblings().find('.c-product-tile__name').text().trim();
                productDetails = $(this).find('.js-product-impression').data('product-details');
            } 
            var actionFieldList = 'Search';
            window.sessionStorage.actionFieldList = actionFieldList;
            var productPosition = $(this).parents('.c-search-suggestion__product-item').index() + 1;
            window.sessionStorage.productPosition = productPosition;
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'Search',
                    'action': 'click_product',
                    'label': productName !== '' ?  productName : productDetails.name
                },
                'ecommerce': {
                    'currencyCode': analyticsData.page.currencyCode,
                    'click': {
                        'actionField': {
                            'list': actionFieldList
                        },
                        'products': [{
                            'name': productDetails.name,
                            'id': productDetails.id,
                            'price': productDetails.price,
                            'brand': productDetails.brand,
                            'category': productDetails.category,
                            'variant': productDetails.variant,
                            'position': productPosition,
                            'list': actionFieldList
                        }]
                    }
                }
            });
        });
    },

    'socialClick': function () {
        $(document).on('click', '.js-footer-social a', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'footer',
                    'action': 'click_social',
                    'label': $(this).html()
                }
            });
        });
    },

    'findStoreClick': function () {
        $(document).on('click', '.c-footer__find-stores a', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'footer',
                    'action': 'find_store',
                    'label': undefined
                }
            });
        });
    },

    'subscribeFooter': function () {
        $(document).on('newsletterSubscription.success', function (event, $form) {
            var preference = '',
                preferences = '';

            if ($form) {
                $form.find('input[name*="_type_"]:checked').each(function () {
                    preference = $(this).siblings('.js-input-label').find('.form-row_value').text();
                    if (preference) {
                        preferences = preferences ? preferences + ', ' + preference : preference;
                    }
                });
            }
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'footer',
                    'action': 'subscribe_ok',
                    'label': preferences
                }
            });
        });
    },

    'sliderClick': function (analyticsData = {}) {
        $(document).on('click', '.js-swiper:not(.carousel-disabled) .swiper-slide', function () {
            var position = ($(this).data('swiper-slide-index') || $(this).index()) + 1;
            var destination = 'none';

            if ($(this).find('video').length) {
                destination = 'video';
            } else if ($(this).find('a').length) {
                destination = $(this).find('a')[0].pathname;
            }
            var category = analyticsData.page.pageCategory === 'homepage' ? 'home' : analyticsData.page.pageCategory;
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': category,
                    'action': 'click_slider',
                    'label': position + '-' + destination
                }
            });
        });
    },

    'categoryClick': function (analyticsData = {}) {
        $(document).on('click', '[id$="carousel-categories"] li, [data-analyticscategory]', function () {
            var category = mappings.pageCategory[analyticsData.page.pageCategory] || analyticsData.page.pageCategory;
            var catName; 
            if ($(this).data('analyticscategory')) {
                catName = $(this).data('analyticscategory')
            } else {
                catName = $(this).find('a.name-link').html().trim();
            }
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': category,
                    'action': 'click_category',
                    'label': catName
                }
            });
        });
    },

    'homeBannerClick': function (analyticsData = {}) {
        $(document).on('click', '[data-analyticsbanner]', function () {
            var path = this.pathname;
            var category = mappings.pageCategory[analyticsData.page.pageCategory] || analyticsData.page.pageCategory;
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': category,
                    'action': 'click_banner',
                    'label': path
                }
            });
        });
    },

    'colorClickPDP': function (analyticsData = {}) {
        $(document).on('click', '.color > li > .js-product-variations__link', function () {
            var color = $(this).data('attr-value');
            var color_id = $(this).data('color-id');
            var pageCategory;
            if (analyticsData.page && mappings.pageCategory[analyticsData.page.pageCategory]) {
                pageCategory = mappings.pageCategory[analyticsData.page.pageCategory];
            } else if (pageContext.type && (pageContext.type==='plp' || pageContext.type.toUpperCase() === 'search'.toUpperCase())) {
                pageCategory = mappings.pageCategory['plp'];
            }
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': pageCategory,
                    'action': 'select_color',
                    'label': color,
                    'color_id': color_id
                }
            });
        });
    },

    'colorClick': function (analyticsData = {}) {
        $(document).on('click', '.js-swatch-list > li', function () {
            var color = $(this).data('color-value');
            var color_id = $(this).data('color-id');
            var customData = $(this).closest('.js-product-impression').data('productCd');
            var pageCategory;
            if (analyticsData.page && mappings.pageCategory[analyticsData.page.pageCategory]) {
                pageCategory = mappings.pageCategory[analyticsData.page.pageCategory];
            } else if (pageContext.type && (pageContext.type==='plp' || pageContext.type.toUpperCase() === 'search'.toUpperCase())) {
                pageCategory = mappings.pageCategory['plp'];
            }
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': pageCategory,
                    'action': 'select_color',
                    'label': color,
                    'color_id': color_id
                },
                'customData': {
                    'user': pageContext.user,
                    'category': analyticsData.categoryData,
                    'extraData': {
                        'imageType': customData.imageType || undefined
                    }
                },
            });
        });
        
        $(document).on('click', '.js-more-colors-link', function () {
            var customData = $(this).closest('.js-product-impression').data('productCd');
            var productDetails = $(this).closest('.js-product-impression').data('productDetails');
            var actionFieldList = productDetails.hasOwnProperty('list') ? productDetails.list : '';

            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'category',
                    'action': 'colour_options',
                    'label': productDetails.name
                },
                'customData': {
                    'user': pageContext.user,
                    'category': analyticsData.categoryData,
                    'extraData': {
                        'imageType': customData.imageType || undefined
                    }
                },
                'ecommerce': {
                    'currencyCode': analyticsData.page.currencyCode,
                    'click': {
                        'actionField': {
                            'list': actionFieldList
                        },
                        'products': [{
                            'name': productDetails.name,
                            'id': productDetails.id,
                            'price': productDetails.price,
                            'brand': productDetails.brand,
                            'category': productDetails.category,
                            'variant': productDetails.variant,
                            'position': $(this).parent().index() + 1
                        }]
                    }
                }
            });
        });
    },

    'sizeClick': function (analyticsData = {}) {
        $(document).on('click', '.size > li > .js-product-variations__link', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': analyticsData.page.pageCategory,
                    'action': 'click_size',
                    'label': $(this).data('attr-value')
                }
            });
        });
    },

    'sizeGuideClick': function () {
        $(document).on('click', '.js-size-guide-link', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'product',
                    'action': 'click_guide'
                }
            });
        });
    },

    'shareClick': function () {
        $(document).on('click', '.js-social-sharing', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'product',
                    'action': 'share',
                    'label': $(this).data('share')
                }
            });
        });
    },

    'createAccount': function () {
        $(document).on('click', '#account-create-button', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'login',
                    'action': 'go_create_account'
                }
            });
        });
    },

    'loginError': function (analyticsData = {}) {
        if ($('.js-login__form').find('.error-form').length) {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': analyticsData.page.pageCategory === 'checkoutlogin' ? 'funnel_account' : 'login',
                    'action': 'login_error',
                    'label': $('.js-login__form').find('.error-form').html().trim()
                }
            });
        }
    },

    'registrationError': function () {
        if ($('#RegistrationForm').find('.error').length) {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'register',
                    'action': 'create__account_error ',
                    'label': $('#RegistrationForm').find('.error').html().trim()
                }
            });
        }
    },

    'checkoutGuest': function () {
        $(document).on('click', '#account-checkout-guest-button', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'funnel_account',
                    'action': 'go_guest',
                    'label': undefined
                }
            });
        });
    },

    'viewBag': function () {
        $(document).on('click', '#minicart-viewbag-button,#add-to-cart-toast .o-btn-sm-tertiary', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': $(this).parents('#add-to-cart-toast').hasClass('added-to-bag-modal') ? 'popup_bag':'header_bag',
                    'action': 'go_bag'
                },
                'ecommerce': undefined
            });
        });
    },

    'previewBag': function() {
        $(document).on('click', '.js-preview-bag', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'header_bag',
                    'action': 'open_bag'
                }
            });
        });
    },

    'closePreviewBag': function() {
        $(document).on('minicart.close', (e, avoidAnalytics) => {
            if (avoidAnalytics) {
                return;
            }
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'header_bag',
                    'action': 'close_bag'
                }
            });
        });
    },

    'proceedCheckout': function () {
        $(document).on('click', '#minicart-proceed-checkout-button, .js-proceed-checkout,#add-to-cart-toast .o-btn-sm-primary', function (e) {
            e.stopPropagation();
            var categoryValue;
            if($(this).hasClass('js-proceed-checkout')){
                categoryValue = 'funnel_cart';
            } else if($(this).parents('#add-to-cart-toast').hasClass('added-to-bag-modal')){
                categoryValue = 'popup_bag';
            } else {
                categoryValue = 'header_bag';
            }
            var products = pageContext.analytics.basket ? pageContext.analytics.basket.ecommerce : {};
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': categoryValue,
                    'action': 'go_checkout'
                },
                'ecommerce': {
                    'currencyCode': pageContext.analytics.page.currencyCode,
                    'checkout': {
                        'actionField': {
                            'step': 'step 0'
                        },
                        'products': products
                    }
                }
            });
        });
    },

    'goPayment': function () {
        $(document).on('click', '.js-submit-checkout, .js-edit-delivery, .js-checkout__button', function (e, avoidAnalytics) {
            if (avoidAnalytics) {
                return;
            }
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'funnel_delivery',
                    'action': 'go_payment',
                    'label': $(this).data('location') || 'down'
                },
                'ecommerce': undefined
            });
        });
    },

    'editBag': function (analyticsData = {}) {
        $(document).on('click', '.js-checkout-edit-bag', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': analyticsData.checkout && analyticsData.checkout.step === "2" ? 'funnel_delivery' : 'funnel_ payment',
                    'action': 'edit_bag'
                },
                'ecommerce': undefined
            });
        });
    },

    'placeOrder': function () {
        $(document).on('click', '.js-billing-submit', function (e, avoidAnalytics) {
            if (avoidAnalytics) {
                return;
            }
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'funnel_ payment',
                    'action': 'place_order',
                    'label': $(this).data('location')
                },
                'ecommerce': undefined
            });
        });
    },

    'paymentError': function () {
        if ($('.js-server-side-error').length) {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'funnel_ payment',
                    'action': 'payment_error',
                    'label': $('.js-payment-method:checked').val()
                },
                'ecommerce': undefined
            });
        };
    },

    'editDelivery': function (analyticsData = {}) {
        $(document).on('click', '.js-edit-delivery', function () {
            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': analyticsData.checkout && analyticsData.checkout.step === "2" ? 'funnel_delivery' : 'funnel_ payment',
                    'action': 'edit_delivery',
                    'label': undefined
                },
                'ecommerce': undefined
            });
        });
    },

    'emailMeWhenAvailable' : function () {
        $(document).on('submit', '.js-emailme-form', function () {
            var pid = $(this).find('.js-input').prop('value');
            dataLayer.push({
                'event' : 'analyticsEvent',
                'eventData' : {
                    'category' : 'product' ,
                    'action' : 'send_notify',
                    'label' : pid 
                }
            });
        });
    },

    'globale': function (data) {
        if (!('gle' in window)) {
            return;
        }

        gle('OnCheckoutStepLoaded', function (data) {
            switch (data.StepId) {
                case data.Steps.LOADED:
                    if (SitePreferences.MONETATE_ENABLED) {
                        monetateData.setPageType = 'checkout';
                        window.monetateQ.push(['setPageType', monetateData.setPageType]);
                        if (monetateData.addCartRows && monetateData.addCartRows.length!=0) {
                            window.monetateQ.push(['addCartRows', monetateData.addCartRows]);
                        }
                        if (monetateData.breadCrumbs) {
                            window.monetateQ.push(['addBreadcrumbs', monetateData.breadCrumbs]);
                        }
                        window.monetateQ.push(['trackData']);
                    }

                    break;
                case data.Steps.CONFIRMATION:
                    if (SitePreferences.MONETATE_ENABLED) {
                        monetateData.setPageType = 'purchase';
                        window.monetateQ.push(['setPageType', monetateData.setPageType]);
                        var PurchaseRows = [];
                        var dataDetails = data.details;
                        for (var i = 0; i < dataDetails.products.length; i++) {
                            var purchaseProduct = dataDetails.products[i];
                            PurchaseRows.push({
                                purchaseId: dataDetails.OrderReference,
                                productId: purchaseProduct.productGroupCode,
                                quantity: purchaseProduct.quantity,
                                unitPrice: purchaseProduct.customerPrice,
                                currency: dataDetails.customerCurrency,
                                sku: purchaseProduct.sku
                            });
                        }
                        window.monetateQ.push(['addPurchaseRows', PurchaseRows]);
                        window.monetateQ.push(['trackData']);
                    }

                    if (data.IsSuccess) {
                        var totalOriginalCartDiscount = 0;
                        var products = [];

                        for (i = 0; i < data.details.discounts.length; i++) {
                            if (data.details.discounts[i].discountTypeId == 1) {
                                totalOriginalCartDiscount = totalOriginalCartDiscount + data.details.discounts[i].price;
                            }
                        }

                        for (var i = 0; i < data.details.products.length; i++) {
                            var currentProduct = data.details.products[i];

                            products.push({
                                id: currentProduct.sku,
                                name: currentProduct.name,
                                category: currentProduct.categories.length ? currentProduct.categories[0].name : '',
                                price: currentProduct.price,
                                quantity: currentProduct.quantity
                            });
                        }

                        var analyticsData = {
                            page: {
                                currencyCode: data.details.currency
                            },
                            purchase: {
                                actionField: {
                                    id: data.MerchantOrderId,
                                    revenue: data.details.totalProductsPrice - totalOriginalCartDiscount,
                                    tax: data.details.totalVAT,
                                    shipping: data.details.discountedShippingPrice,
                                    coupon: ''
                                },
                                products: products
                            }
                        };

                        pageDataCollectors.purchase(analyticsData);
                    }
            }
        });
    },

    'gridViewModeClick': function (analyticsData = {}) {
        $(document).on('viewMode.grid.modeChanged', function (event, data) {
            var label;

            if (!data) { return; }
            
            switch (data.gridViewMode) {
                case 'sparse':
                    label = 'two-images';
                    break;
                case 'condensed':
                    label = 'four-images';
                    break;
            }

            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'category',
                    'action': 'click_image_group',
                    'label': label
                },
                'category': analyticsData.categoryData,
                'customData': {
                    'user': pageContext.user,
                    'category': analyticsData.categoryData,
                    'extraData': {
                        'imageType': data.productViewMode
                    }
                },
                'ecommerce': undefined
            });
        });
    },

    'productViewModeClick': function (analyticsData = {}) {
        $(document).on('viewMode.product.modeChanged', function (event, data) {
            if (!data) { return; }

            dataLayer.push({
                'event': 'analyticsEvent',
                'eventData': {
                    'category': 'category',
                    'action': 'click_image_type',
                    'label': data.productViewMode
                },
                'category': analyticsData.categoryData,
                'customData': {
                    'user': pageContext.user,
                    'category': analyticsData.categoryData,
                    'extraData': {
                        'imageType': data.productViewMode
                    }
                },
                'ecommerce': undefined
            });
        });
    },

    'productContextMenuOpen': function(analyticsData = {}) {
        $(document).on('contextmenu', '.js-product-impression a:not(.swatch, .js-more-colors-link), .js-product-nav', function (e) {
            if ($(e.target).hasClass('js-slider-button') || $(e.target).parent().parent().hasClass('js-slider-button') || $(e.target).hasClass('c-quick-shop__bag-icon') || $(e.target).hasClass('c-quick-shop__close-icon')) {
                return;
            }
            var productDetails = $(this).data('productDetails');
            if (!productDetails) {
                productDetails = $(this).closest('.js-product-impression, .c-mini-cart__product-item-row, .c-products-table__item').data('productDetails');
            }
            setActionPosition(e.target, this, productDetails);
        });
    },

    // GA4 new events
    //productImpressions Event in UA - viewItemList Event in ga4
    //product impressions - plp, searchresultpage, PDP Recomm, Cart Recomm, nosearchresultpage recomm
    'viewItemList': function (analyticsData = {}) {
        $(document).on('window.scroll', function () {
            pageDataCollectors.viewItemList(analyticsData);
            pageDataCollectors.promoImpression(analyticsData);
        });

        $(document).on('click', '.jcarousel-nav', function () {
            setTimeout(function () {
                pageDataCollectors.viewItemList(analyticsData);
            }, 600);
        });

        $(document).on('swiperslideChangeTransitionEnd', function() {
            setTimeout(function () {
                pageDataCollectors.viewItemList(analyticsData);
            }, 300);
        });
    },

    //productClick Event in UA - selectItem Event in ga4
    //product click from anywhere - plp, PDP, Carousels, Cart, Order summary on shipping, billing
    'selectItem': function (analyticsData = {}) {
        $(document).on('click', '.js-product-impression a:not(.swatch, .js-more-colors-link), .js-product-nav, .video-content .video-content-anchor, .link-block', function (e) {
            // Prevent sending "click_product" event when user clicks on slider controls
            e.stopPropagation();
            if ($(e.target).hasClass('js-slider-button') || $(e.target).parent().parent().hasClass('js-slider-button') || $(e.target).hasClass('c-quick-shop__bag-icon') || $(e.target).hasClass('c-quick-shop__close-icon')) {
                return;
            }

            var productDetails = $(this).data('productDetails');
            if (!productDetails) {
                productDetails = $(this).closest('.js-product-impression, .c-mini-cart__product-item-row, .c-products-table__item').data('productDetails');
            }

            var customData = $(this).data('productCd');
            if (!customData) {
                customData = $(this).closest('.js-product-impression').data('productCd') || {};
            }

            var selectedView = $('.js-cmp-gridView');
            var selectedViewValue = 'not apply';     
            if(selectedView.data('cmp') == 'gridView'){
                selectedViewValue = selectedView.data('json-config').preSelectedViewMode;
            }
            if (productDetails) {
                var $this = $(this);
                var item_concatcategory = 'not apply',categoryArray=[];
                var imageType = '',eventCategoryValue='',discountDifference='',discountPercentage='', fullPrice='', dlListValue=productDetails.list, productPosition=0;
                var recommendationsCarouselData = $this.parents('.recommendations-carousel');
                var recommendationsCarouselTitle = recommendationsCarouselData.data('display-title');
                var recentlyViewedDiv = $this.parents('.recently-viewed-carousel');
                var isMinicart = $(this).parents().hasClass('c-mini-cart__product-item-row');
                var isCartPage = $(this).parents().hasClass('c-products-table__item');
                var isTriggeredFromCheckout = $(this).parents('.product-edit-modal').hasClass('triggeredFromCheckout');
                var impressionDetails = $this.closest('.js-product-impression').data('product-details');
                
                if(isMinicart){
                    eventCategoryValue = 'header_bag';
                    impressionDetails = $this.closest('.c-mini-cart__product-item-row').data('product-details');
                    productPosition = $(this).parents('.js-line-item-row').index() + 1;
                    dlListValue = 'minibag';
                    if((pageContext.type.toUpperCase() == 'plp'.toUpperCase()) || (pageContext.type.toUpperCase() == 'search'.toUpperCase())){
                        selectedViewValue = 'not apply';
                    }
                } else if(isCartPage){
                    eventCategoryValue = 'funnel_cart';
                    impressionDetails = $this.closest('.c-products-table__item').data('product-details');
                    productPosition = $(this).parents('.js-line-item-row').index() + 1;
                    dlListValue='cart';
                } else if(pageContext.type.toUpperCase() === 'search'.toUpperCase()){
                        if(recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0){
                            eventCategoryValue = 'carousel';
                            dlListValue = recommendationsCarouselTitle;
                            productPosition = $(this).parents('.js-product-impression').data('json-config').productPosition;
                        } else {
                            eventCategoryValue = 'search_list';
                            dlListValue = 'Search';
                            productPosition = $(this).closest('.js-product-impression, .c-mini-cart__product-item-row, .c-products-table__item').data('jsonConfig').productPosition; 
                        }
                            
                } else if (recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0) {
                    eventCategoryValue = 'carousel';
                    dlListValue = (( recentlyViewedDiv.length > 0 ) ? recentlyViewedDiv.find('.recently-viewed-carousel__title')[0].innerHTML :  recommendationsCarouselTitle);
                    productPosition = $(this).parents('.js-product-impression').data('json-config').productPosition;
                } else {
                    eventCategoryValue = 'category';
                    productPosition = $(this).closest('.js-product-impression, .c-mini-cart__product-item-row, .c-products-table__item').data('jsonConfig').productPosition; 
                }
                
                if(impressionDetails){
                    discountDifference = parseFloat(impressionDetails.standardPrice - impressionDetails.price).toFixed(2).replace(/[.,]00/, "");
                    discountPercentage = impressionDetails.discount;
                    fullPrice = impressionDetails.standardPrice
                }

                //imageType is model/product
                var imageSrcUrl;
                if(isMinicart || isCartPage || isTriggeredFromCheckout){
                    //on click of minicart/cart item product image or name
                    imageSrcUrl = $(this).parents('.js-line-item-row').find('.js-image').data('zoom-src');
                } else {
                    //on click of plp/searchresult page item product image or name
                    imageSrcUrl = $(this).parents('.c-product-tile__wrapper').find('.image-src-url')[0].value;
                }
                if(imageSrcUrl.includes('FL') ){
                    imageType = "product";
                } else if(imageSrcUrl.includes('MO')){
                    imageType = "model";
                }

                if(productDetails.category){
                    item_concatcategory = productDetails.category;
                    categoryArray = item_concatcategory.split("/");
                }
                var analyticsEvent = {
                    'event': 'select_item',
                    'ecommerce':{
                        'event_category' :  eventCategoryValue,
                        'items': [{
                            'item_id': productDetails.id,
                            'item_name': productDetails.name,
                            'price': productDetails.price,
                            'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                            'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                            'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                            'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                            'item_concatcategory' : item_concatcategory,
                            'item_variant': productDetails.variant,
                            'item_wash': (productDetails.wash && productDetails.wash!='undefined' ? productDetails.wash : 'not apply'),
                            'item_variation_group': (productDetails.wash && productDetails.wash!='undefined' ? (productDetails.id + productDetails.wash) : (productDetails.id + productDetails.variant)),
                            'item_size': (productDetails.dimension15 ? productDetails.dimension15 : 'not apply'),
                            'item_length': (productDetails.length ? productDetails.length : 'not apply'),
                            'item_list_name': dlListValue,
                            'item_list_id': dlListValue,
                            'index': productPosition,
                            'quantity': 1,
                            'discount': (discountDifference ? discountDifference : '0'),
                            'item_image_type': imageType,
                            'item_grid_type': selectedViewValue,
                            'item_full_price': ((discountDifference == 0)? 'yes' : 'no'),
                            'item_discount_percentage': (discountPercentage ? discountPercentage : '0')
                        }]
                    }
                } 
            }
            if (SitePreferences.ATTRAQT_ENABLED) {
                var isMinicart = $(this).parents().hasClass('c-mini-cart__product-item-row');
                var isCartPage = $(this).parents().hasClass('c-products-table__item');
                var isOrderSummary = $(this).parents().hasClass('order-summary-data');
                if (!isMinicart && !isCartPage && !isOrderSummary){
                    var sessionIdVal = $('#wrapper').find('#sessionId');
                    var sessionIdValue;
                    sessionIdValue = sessionIdVal.val();

                    var sourceIdValue='';
                    var sourceIdVal = $('#wrapper').find('#sourceId');
                        if(sourceIdVal.length > 0){
                            sourceIdValue = sourceIdVal.val(); 
                        }

                    
                    var attraqtdata = {
                        'action':'click',
                        'target': {
                        },
                        'metadata': {
                            'locale' : pageContext.currentLocale
                        },
                        'sourceId': sourceIdValue,
                        'sessionId': sessionIdValue
                    }
                    var campaignIdValue = $(this).data('campaign-id');
                    var isVideoAttraqt = $(this).hasClass('video-content-anchor');
                    var isPLPBannerAttraqt = $(this).hasClass('link-block');
                    if (isVideoAttraqt || isPLPBannerAttraqt) {
                        var analyticsEvent = {
                            'event': 'analyticsEvent',
                        }    
                        var productUrl = $(this).attr('href');
                        var urlSubs = productUrl.substring(productUrl.lastIndexOf('/') + 1);
                        var subStr = urlSubs.substring(0, urlSubs.lastIndexOf('.'));
                        var subStr1 = subStr.substring(subStr.length, subStr.lastIndexOf('-'));
                        var productIdAttraqt = subStr1.substring(subStr1.lastIndexOf('-') + 1);
                        attraqtdata.target.product = productIdAttraqt;
                    } else {   
                        attraqtdata.target.product = productDetails.variationgroupid; 
                    }
                    if(campaignIdValue){
                        attraqtdata.target.campaign = campaignIdValue;
                    }
                    analyticsEvent.attraqtdata = attraqtdata;
                }
            }
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push(analyticsEvent);     
        });
    },
    
    //searchProductClick Event in UA - searchSelectItem Event in ga4
    //product click and product name click from search suggestion
    'searchSelectItem': function (analyticsData = {}) {
        $(document).on('click', '.js-search-suggestion__product-link', function () {
            var productDetails = $(this).data('productDetails');
            var productName = $(this).find('.c-product-tile__name');
            if (!productDetails) {
                productDetails = $(this).parents('.c-search-suggestion__product-item').find('.js-product-impression').data('productDetails');
                if(productName.length>0){
                    productDetails = productName.data('productDetails');
                }
            }
            var selectedView = $('.js-cmp-gridView');
            var selectedViewValue = 'not apply';     
            if(selectedView.data('cmp') == 'gridView'){
                selectedViewValue = selectedView.data('json-config').preSelectedViewMode;
            }
            if (productDetails) {
                var item_concatcategory = 'not apply',categoryArray=[];
                var imageType = '',eventCategoryValue='',discountDifference='',discountPercentage='', fullPrice='', dlListValue='Search';
                eventCategoryValue = 'search';
                discountDifference = parseFloat(productDetails.standardPrice - productDetails.price).toFixed(2).replace(/[.,]00/, "");
                discountPercentage = productDetails.discount;
                fullPrice = productDetails.standardPrice
                
               //imageType is model/product
               var imageSrcUrl = $(this).parents('.c-search-suggestion__product-item').find('.image-src-url')[0].value;
               if(imageSrcUrl.includes('FL') ){
                    imageType = "product";
                } else if(imageSrcUrl.includes('MO')){
                    imageType = "model";
                }
                var productPosition = $(this).parents('.c-search-suggestion__product-item').index() + 1;
                if(productDetails.category){
                    item_concatcategory = productDetails.category;
                    categoryArray = item_concatcategory.split("/");
                }
                var analyticsEvent = {
                    'event': 'select_item',
                    'ecommerce':{
                        'event_category' :  eventCategoryValue,
                        'items': [{
                            'item_id': productDetails.id,
                            'item_name': productDetails.name,
                            'price': productDetails.price,
                            'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                            'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                            'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                            'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                            'item_concatcategory' : item_concatcategory,
                            'item_variant': productDetails.variant,
                            'item_wash': (productDetails.wash && productDetails.wash!='undefined' ? productDetails.wash : 'not apply'),
                            'item_variation_group': (productDetails.wash && productDetails.wash!='undefined' ? (productDetails.id + productDetails.wash) : (productDetails.id + productDetails.variant)),
                            'item_size': (productDetails.dimension15 ? productDetails.dimension15 : 'not apply'),
                            'item_length': (productDetails.length ? productDetails.length : 'not apply'),
                            'item_list_name': dlListValue,
                            'item_list_id': dlListValue,
                            'index': productPosition,
                            'quantity': 1,
                            'discount': (discountDifference ? discountDifference : '0'),
                            'item_image_type': imageType,
                            'item_grid_type': selectedViewValue,
                            'item_full_price': ((discountDifference == 0) ? 'yes' : 'no'),
                            'item_discount_percentage': (discountPercentage ? discountPercentage : '0')
                        }]
                    }
                } 
            }
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push(analyticsEvent);   
        });
    },

    //addToCart Event in UA - addItemsToCart Event in ga4
    //on addtocart from PDP, edit popup, cart +, minicart +, variation changed in popup
    'addItemsToCart': function (analyticsData = {}) {
        analyticsData.page = analyticsData.page || {};
        analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';

        $(document).on('product.addedToCart', function (event, $form, qty) {
            if ($form) {
                var productDetails = $form.data('productDetails'),
                    isMinicart = $form.hasClass('c-mini-cart__product-item-row') || $form.parents('.product-edit-modal').hasClass('miniBagTriggered'),
                    isCartPage = $form.hasClass('c-products-table__item') || $form.parents('.product-edit-modal').hasClass('cartTriggered'),
                    isEditPopup = $form.parents('.product-edit-modal').length > 0 ? true : false,
                    isTriggeredFromCheckout = $form.parents('.product-edit-modal').hasClass('triggeredFromCheckout'),
                    actionFieldList = productDetails && productDetails.hasOwnProperty('list') ? productDetails.list : '';
                    
                var selectedViewValue = 'not apply', item_concatcategory = 'not apply', categoryArray,discountDifference = '', discountPercentage = '', fullPrice = '',
                list='', imageType='';     
                if(window.sessionStorage.gridViewType){
                    selectedViewValue = window.sessionStorage.gridViewType;
                }
            
                //imageType is model/product
                var imageSrcUrl = $('.js-zoom img').data('src') || $form.data('product-details').imageUrl;
                if(imageSrcUrl.includes('FL') ){
                    imageType = "product";
                } else if(imageSrcUrl.includes('MO')){
                    imageType = "model";
                }

                
                if (isEditPopup) {
                    var selectedQuantity = parseInt($form.siblings('.quantity-select').find('.js-item-quantity-field').val() || $form.find('#quantity').val());
                    var originalQTY = $form.parents('.js-edit-cart').data('trigger-initial-qty');
                    var changedQTY = selectedQuantity;
                    var isVariationChanged = $form.parents('.js-edit-cart').data('variation-changed');
                    if(!isVariationChanged){//if variation changed
                        if(selectedQuantity != originalQTY){
                            changedQTY = selectedQuantity - originalQTY;
                        }
                    }
                    if(isTriggeredFromCheckout){
                        changedQTY = $form.parents('.js-edit-cart').data('trigger-initial-qty') || 1;
                    }
                }

                if (isCartPage) {
                    actionFieldList = "cart"
                } else if (isMinicart && !isTriggeredFromCheckout) {
                    actionFieldList = "minibag"
                } else if(isTriggeredFromCheckout) {
                    actionFieldList = "ordersummary"
                }
                if(window.sessionStorage.actionFieldList){
                    actionFieldList = window.sessionStorage.actionFieldList;
                }

                if (productDetails) {
                    productDetails.quantity = changedQTY || qty || 1 ;
                    productDetails.list = actionFieldList;
                    if (isCartPage) {
                        productDetails.list = 'cart';
                        if(isEditPopup === false) {
                            productDetails.position = $('.c-products-table .c-products-table__item').index($(event.currentTarget.activeElement).parents('.c-products-table__item')) + 1;
                        } else {
                            productDetails.position = $form.parents('.js-edit-cart').attr('data-trigger-pos');
                        }
                        selectedViewValue = 'not apply';
                    } else if (isMinicart && !isTriggeredFromCheckout) {
                        productDetails.list = 'minibag';
                        if(isEditPopup === true) {
                            productDetails.position = $form.parents('.js-edit-cart').attr('data-trigger-pos');
                        }
                        selectedViewValue = 'not apply';
                    } else if(isTriggeredFromCheckout) {
                        productDetails.list = 'ordersummary';
                        productDetails.position = $form.parents('.js-edit-cart').attr('data-trigger-pos');
                        selectedViewValue = 'not apply';
                    }
                    discountDifference = parseFloat(productDetails.standardPrice - productDetails.price).toFixed(2).replace(/[.,]00/, "");
                    discountPercentage = productDetails.discount;
                    fullPrice = productDetails.standardPrice;
                    list=productDetails.list;
                    if(productDetails.category){
                        item_concatcategory = productDetails.category;
                        categoryArray = item_concatcategory.split("/");
                    }
                    var eventCategoryValue = '';
                    if(pageContext.type.toLowerCase()=='product' && !isEditPopup && !isMinicart){
                        eventCategoryValue = "product";
                    } else if(isCartPage){
                        eventCategoryValue='funnel_cart';
                        if(isEditPopup){
                            eventCategoryValue='funnel_cart_editor';
                        }
                    } else if(isMinicart && !(pageContext.dlContentGroup.toLowerCase()=='shipping') && !(pageContext.dlContentGroup.toLowerCase()=='billing')){
                        eventCategoryValue='header_bag';
                        if(isEditPopup){
                            eventCategoryValue='header_bag_editor';
                        }
                    } else if(pageContext.dlContentGroup.toLowerCase()=='shipping'){
                        eventCategoryValue='funnel_delivery_editor';
                    } else if(pageContext.dlContentGroup.toLowerCase()=='billing'){
                        eventCategoryValue='funnel_payment_editor';
                    }
                    var analyticsEvent = {
                        'event': 'add_to_cart',
                        'ecommerce':{
                            'event_category' :  eventCategoryValue,
                            'currency': pageContext.analytics.page.currencyCode,
                            'value': (productDetails.price * productDetails.quantity),
                            'items': [{
                                'item_name' : productDetails.name,
                                'item_id' : productDetails.id,
                                'price': productDetails.price,
                                'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                                'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                                'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                                'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                                'item_concatcategory' : item_concatcategory,
                                'item_variant': productDetails.variant,
                                'item_wash': (productDetails.wash && productDetails.wash!='undefined' ? productDetails.wash : 'not apply'),
                                'item_variation_group': (productDetails.wash && productDetails.wash!='undefined' ? (productDetails.id + productDetails.wash) : (productDetails.id + productDetails.variant)),
                                'item_size': (productDetails.dimension15 ? productDetails.dimension15 : 'not apply'),
                                'item_length': ((productDetails.length == 'undefined') ? 'not apply' : productDetails.length),
                                'item_list_name': list,
                                'item_list_id': list,
                                'index': productDetails.position,
                                'quantity': productDetails.quantity || 1,
                                'discount' : (discountDifference ? discountDifference : '0'),
                                'item_image_type': imageType,
                                'item_grid_type': selectedViewValue,
                                'item_full_price': ((discountDifference == 0) ? 'yes' : 'no'),
                                'item_discount_percentage': (discountPercentage ? discountPercentage : '0')
                            }]
                        }
                    }
                    if(SitePreferences.ATTRAQT_ENABLED){
                        var sessionIdVal = $('#wrapper').find('#sessionId');
                        var sessionIdValue;
                        if (sessionIdVal.length > 0) {
                            sessionIdValue = sessionIdVal.val();
                        }
                        var sourceIdValue = '';
                        var sourceIdVal = $('#wrapper').find('#sourceId')
                        if(sourceIdVal.length > 0){
                            sourceIdValue = sourceIdVal.val();
                        }
                        var attraqtdata = {
                            'action':'add-to-cart',
                            'target': {
                                'product': productDetails.id + productDetails.variant
                            },
                            'metadata': {
                                'quantity': productDetails.quantity,
                                'locale' : pageContext.currentLocale
                            },
                            'sourceId': sourceIdValue,
                            'sessionId': sessionIdValue
                        } 
                        analyticsEvent.attraqtdata = attraqtdata;
                    }
                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                    dataLayer.push(analyticsEvent);
                }
            }
        });
    },

    //quickShopAddToCart Event in UA - quickShopAddItemsToCart Event in ga4
    //on quick shop addtocart from PLP, searchresult page
    'quickShopAddItemsToCart': function (analyticsData = {}) {
        analyticsData.page = analyticsData.page || {};
        analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';

        $(document).on('quickShop.addedToCart', function (event, data, $element) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var $el = $element.closest('.product-tile');
            var eventDataCategory = 'category';
            var productDetails = $element.data('productDetails') || {};
            var actionFieldList = productDetails.hasOwnProperty('list') ? productDetails.list : '';
            if(pageContext.type.toUpperCase() === 'search'.toUpperCase()) {
                actionFieldList = 'Search';
                eventDataCategory = 'search_list';
            } 
            var selectedView = $('.js-cmp-gridView'),
            selectedViewValue = 'not apply', item_concatcategory = 'not apply', categoryArray,discountDifference = '', discountPercentage = '', fullPrice = '',
            imageType='';     
            if(selectedView.data('cmp') == 'gridView'){
                selectedViewValue = selectedView.data('json-config').preSelectedViewMode;
            }
            if(productDetails){
                //imageType is model/product
                var imageSrcUrl = productDetails.imageUrl;
                if(imageSrcUrl.includes('FL') ){
                    imageType = "product";
                } else if(imageSrcUrl.includes('MO')){
                    imageType = "model";
                }
                discountDifference = parseFloat(productDetails.standardPrice - productDetails.price).toFixed(2).replace(/[.,]00/, "");
                discountPercentage = productDetails.discount;
                fullPrice = productDetails.standardPrice;
                if(productDetails.category){
                    item_concatcategory = productDetails.category;
                    categoryArray = item_concatcategory.split("/");
                }
                productDetails.list = actionFieldList;
            }
            var analyticsEvent = {
                'event': 'add_to_cart',
                'ecommerce':{
                    'event_category' : eventDataCategory,
                    'currency': pageContext.analytics.page.currencyCode,
                    'value': productDetails.price,
                    'items': [{
                        'item_name' : productDetails.name,
                        'item_id' : productDetails.id,
                        'price': productDetails.price,
                        'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                        'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                        'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                        'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                        'item_concatcategory' : item_concatcategory,
                        'item_variant': productDetails.variant,
                        'item_wash': (productDetails.wash && productDetails.wash!='undefined' ? productDetails.wash : 'not apply'),
                        'item_variation_group': (productDetails.wash && productDetails.wash!='undefined' ? (productDetails.id + productDetails.wash) : (productDetails.id + productDetails.variant)),
                        'item_size': (productDetails.dimension15 ? productDetails.dimension15 : 'not apply'),
                        'item_length': ((productDetails.length == 'undefined') ? 'not apply' : productDetails.length),
                        'item_list_name': productDetails.list,
                        'item_list_id': productDetails.list,
                        'index': productDetails.position,
                        'quantity': productDetails.quantity || 1,
                        'discount' : (discountDifference ? discountDifference : '0'),
                        'item_image_type': imageType,
                        'item_grid_type': selectedViewValue,
                        'item_full_price': ((discountDifference == 0) ? 'yes' : 'no'),
                        'item_discount_percentage': (discountPercentage ? discountPercentage : '0')
                    }]
                }
            }
            if(SitePreferences.ATTRAQT_ENABLED){
                var sessionIdVal = $('#wrapper').find('#sessionId');
                var sessionIdValue;
                if (sessionIdVal.length > 0) {
                    sessionIdValue = sessionIdVal.val();
                }
                var sourceIdValue = '';
                var sourceIdVal = $('#wrapper').find('#sourceId')
                if(sourceIdVal.length > 0){
                    sourceIdValue = sourceIdVal.val();
                }
                var attraqtdata = {
                    'action':'add-to-cart',
                    'target': {
                        'product': productDetails.id + productDetails.variant
                    },
                    'metadata': {
                        'quantity': productDetails.quantity,
                        'locale' : pageContext.currentLocale
                    },
                    'sourceId': sourceIdValue,
                    'sessionId': sessionIdValue
                } 
                analyticsEvent.attraqtdata = attraqtdata;
            }
            dataLayer.push(analyticsEvent);
        });
    },

    //quickShopAddToCartCarousel Event in UA - quickShopAddItemsToCartCarousel Event in ga4
    //on quick shop of recomm(recently viewed and you may also like) from PDP, No search result page, cart page
    'quickShopAddItemsToCartCarousel':  function (analyticsData = {}) { 
        analyticsData.page = analyticsData.page || {};
        analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';

        $(document).on('quickShop.addedToCartCarousel', function (event, data, $element) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var $el = $element.closest('.product-tile');
            var productDetails = $element.data('productDetails') || {};

            var jsProductImpression = $el.closest('.product-tile.js-product-impression');
            var jsProductImpressionProductDetails = jsProductImpression.data('json-config');
            productDetails.position = jsProductImpressionProductDetails.productPosition;
            var list='', eventCategoryValue='carousel';
            var recommendationsCarouselData = $el.parents('.recommendations-carousel');
            var recommendationsCarouselTitle = recommendationsCarouselData.data('display-title');
            var recentlyViewedDiv = $el.parents('.recently-viewed-carousel');

            if (recentlyViewedDiv.length > 0) {
                list = recentlyViewedDiv.find('.recently-viewed-carousel__title')[0].innerHTML;
            } else if (recommendationsCarouselData.length > 0 && recommendationsCarouselTitle !== undefined) {
                list = recommendationsCarouselData.data('display-title');
            }

            var selectedView = $('.js-cmp-gridView'),
            selectedViewValue = 'not apply', item_concatcategory = 'not apply', categoryArray,discountDifference = '', discountPercentage = '', fullPrice = '',
            imageType='';     
            if(selectedView.data('cmp') == 'gridView'){
                selectedViewValue = selectedView.data('json-config').preSelectedViewMode;
            }            
            if(productDetails){
                //imageType is model/product
                var imageSrcUrl = productDetails.imageUrl;
                if(imageSrcUrl.includes('FL') ){
                    imageType = "product";
                } else if(imageSrcUrl.includes('MO')){
                    imageType = "model";
                }
                discountDifference = parseFloat(productDetails.standardPrice - productDetails.price).toFixed(2).replace(/[.,]00/, "");
                discountPercentage = productDetails.discount;
                fullPrice = productDetails.standardPrice;
                if(productDetails.category){
                    item_concatcategory = productDetails.category;
                    categoryArray = item_concatcategory.split("/");
                }
                productDetails.list = list;
            }
            
            var analyticsEvent = {
                'event': 'add_to_cart',
                'ecommerce':{
                    'event_category' : eventCategoryValue,
                    'currency': pageContext.analytics.page.currencyCode,
                    'value': productDetails.price,
                    'items': [{
                        'item_name' : productDetails.name,
                        'item_id' : productDetails.id,
                        'price': productDetails.price,
                        'item_category' : (categoryArray[0] ? categoryArray[0] : 'not apply'),
                        'item_category2' : (categoryArray[1] ? categoryArray[1] : 'not apply'),
                        'item_category3' : (categoryArray[2] ? categoryArray[2] : 'not apply'),
                        'item_category4' : (categoryArray[3] ? categoryArray[3] : 'not apply'),
                        'item_concatcategory' : item_concatcategory,
                        'item_variant': productDetails.variant,
                        'item_wash': (productDetails.wash && productDetails.wash!='undefined' ? productDetails.wash : 'not apply'),
                        'item_variation_group': (productDetails.wash && productDetails.wash!='undefined' ? (productDetails.id + productDetails.wash) : (productDetails.id + productDetails.variant)),
                        'item_size': (productDetails.dimension15 ? productDetails.dimension15 : 'not apply'),
                        'item_length': ((productDetails.length == 'undefined') ? 'not apply' : productDetails.length),
                        'item_list_name': productDetails.list,
                        'item_list_id': productDetails.list,
                        'index': productDetails.position,
                        'quantity': productDetails.quantity || 1,
                        'discount' : (discountDifference ? discountDifference : '0'),
                        'item_image_type': imageType,
                        'item_grid_type': selectedViewValue,
                        'item_full_price': ((discountDifference == 0)? 'yes' : 'no'),
                        'item_discount_percentage': (discountPercentage ? discountPercentage : '0')
                    }]
                }
            }
            if(SitePreferences.ATTRAQT_ENABLED){
                var sessionIdVal = $('#wrapper').find('#sessionId');
                var sessionIdValue;
                if (sessionIdVal.length > 0) {
                    sessionIdValue = sessionIdVal.val();
                }
                var sourceIdValue = '';
                var sourceIdVal = $('#wrapper').find('#sourceId')
                if(sourceIdVal.length > 0){
                    sourceIdValue = sourceIdVal.val();
                }
                var attraqtdata = {
                    'action':'add-to-cart',
                    'target': {
                        'product': productDetails.id + productDetails.variant
                    },
                    'metadata': {
                        'quantity': productDetails.quantity,
                        'locale' : pageContext.currentLocale
                    },
                    'sourceId': sourceIdValue,
                    'sessionId': sessionIdValue
                } 
                analyticsEvent.attraqtdata = attraqtdata;
            }
            dataLayer.push(analyticsEvent);

        });
    },

    //js-preview-bag Event in UA - viewCart Event in ga4
    //on click of Minibag shopping bag 
    'viewCart': function (analyticsData = {}) {
        $(document).on('click', '.js-preview-bag', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var categoryValue='';
            if($(this).hasClass('c-products-table')){
                categoryValue = 'funnel_cart';
            } else {
                categoryValue = 'header_bag';
            }
            var productsPurchase = '';
            var ecommerceData;
            if(pageContext.type.toUpperCase() === 'plp'.toUpperCase() && window.sessionStorage.ecommerce){
                ecommerceData = JSON.parse(window.sessionStorage.ecommerce);
            } else {
                ecommerceData = pageContext.analytics.basket.ecommerce;
            }
            if(ecommerceData) {
                productsPurchase = setProductsForDataLayer(ecommerceData, productsPurchase);
            }
            var analyticsEvent = {
                'event': 'view_cart',
                'ecommerce':{
                    'event_category' :  categoryValue,
                    'currency': pageContext.analytics.page.currencyCode,
                    'value': ( $(this).data('basket-info') ? $(this).data('basket-info') : '0' ),
                    'items': productsPurchase
                }
            }
            dataLayer.push(analyticsEvent);
        });
    },

    //removeFromCart Event in UA - removeItemsFromCart Event in ga4
    //on - from minicart, - from cart, Remove All from minicart
    'removeItemsFromCart': function (analyticsData = {}) {
        analyticsData.page = analyticsData.page || {};
        analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';

        $(document).on('product.removeFromCart', function (event, $form, qty) {
            var productDetails = $form.data('productDetails');
            var isMinicart = $form.hasClass('c-mini-cart__product-item-row') || $form.parents('.product-edit-modal').hasClass('miniBagTriggered');
            var isCartPage = $form.hasClass('c-products-table__item') || $form.parents('.product-edit-modal').hasClass('cartTriggered');
            var isEditPopup = $('.product-edit-modal').length > 0 ? true : false;
            var productLabel = $form.data('pid');
            var editPopupVariationChanged = $form.parents('.pt_cart').siblings('.ui-dialog').find('.js-edit-cart').data('variation-changed');
            analyticsData.page.currencyCode = analyticsData.page.currencyCode || '';
            productDetails.quantity = parseInt(qty || $form.find('input.js-item-quantity-field').val() || 1);

            if (productDetails) {
                dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                var totalqtytoremove,product,removedqty=0,remainingqtytoremove=0,isremainingzero=false,productsToRemove=[];
                var i,basketObj;
                if(pageContext.type.toUpperCase() === 'plp'.toUpperCase() && window.sessionStorage.ecommerce){
                    basketObj = JSON.parse(window.sessionStorage.ecommerce);
                } else {
                    basketObj = pageContext.analytics.basket.ecommerce;
                }
                totalqtytoremove = qty;
                productDetails = $form.data('productDetails');
                for(i=basketObj.length-1;i>=0;i--){
                    product = basketObj[i];
                    if(remainingqtytoremove!=totalqtytoremove && !isremainingzero){
                        if(product.id == productDetails.id && product.dimension15 == productDetails.dimension15 && product.variant == productDetails.variant && (product.length == productDetails.length || String(product.length) == String(productDetails.length))){
                            if(product.quantity > productDetails.quantity){
                                product.quantity = productDetails.quantity;
                                if(editPopupVariationChanged || (product.quantity-remainingqtytoremove)==0){
                                    removedqty = removedqty+productDetails.quantity;
                                } else {
                                    if(remainingqtytoremove!=0 && product.quantity>=remainingqtytoremove){
                                        product.quantity = remainingqtytoremove;
                                    }
                                    removedqty = removedqty + product.quantity;
                                }
                            } else {
                                if(editPopupVariationChanged || (product.quantity-remainingqtytoremove)==0){
                                    removedqty = removedqty+product.quantity;
                                } else {
                                    if(remainingqtytoremove!=0 && product.quantity>=remainingqtytoremove){
                                        product.quantity = remainingqtytoremove;
                                    }
                                    removedqty = removedqty + product.quantity;
                                } 
                            }
                            remainingqtytoremove = totalqtytoremove - removedqty;

                            var eventCategoryValue = '';
                            if(isCartPage){
                                eventCategoryValue='funnel_cart';
                                if(isEditPopup){
                                    eventCategoryValue='funnel_cart_editor';
                                }
                            } else if(isMinicart && !(pageContext.dlContentGroup.toLowerCase()=='shipping') && !(pageContext.dlContentGroup.toLowerCase()=='billing')){
                                eventCategoryValue='header_bag';
                                if(isEditPopup){
                                    eventCategoryValue='header_bag_editor';
                                }
                            } else if(pageContext.dlContentGroup.toLowerCase()=='shipping'){
                                eventCategoryValue='funnel_delivery_editor';
                            } else if(pageContext.dlContentGroup.toLowerCase()=='billing'){
                                eventCategoryValue='funnel_payment_editor';
                            }
                            productsToRemove = removeItemsFromDataLayer(product,analyticsData,product,$form, productsToRemove);
                            if(remainingqtytoremove<=0){
                                isremainingzero=true;
                            }
                        } else {
                            continue;
                        }
                    }
                } 
                var analyticsEvent = {
                    'event': 'remove_from_cart',
                    'ecommerce':{
                        'event_category' : eventCategoryValue,
                        'currency': pageContext.analytics.page.currencyCode,
                        'value': (productDetails.price * productDetails.quantity),
                        'items': productsToRemove
                    }
                }
                if (SitePreferences.ATTRAQT_ENABLED) {
                    var attraqtdata = {
                        'action':'remove-from-cart',
                        'target': {
                            'product': productLabel
                        }
                    }
                    analyticsEvent.attraqtdata = attraqtdata;
                }
                dataLayer.push(analyticsEvent);           
            }
        });
    },

    // Event in UA - beginCheckout Event in ga4
    //On click of procced to checkout or express from minicart, cart and toast message
    'beginCheckout': function(analyticsData = {}){
        $(document).on('click', '#minicart-proceed-checkout-button, .js-proceed-checkout,#add-to-cart-toast .o-btn-sm-primary,.c-mini-cart__payment-buttons', function (e) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            e.stopPropagation();
            var categoryValue='',expressCheckoutEnabled='not apply';
            
            if(pageContext.type.toLowerCase()=='cart'){  
                categoryValue = 'funnel_cart';
            } else if($(this).parents('#add-to-cart-toast').hasClass('added-to-bag-modal')){
                categoryValue = 'popup_bag';
            } else {
                categoryValue = 'header_bag';
            }
            if($(this).hasClass('c-mini-cart__payment-buttons')){
                expressCheckoutEnabled = $(this).find('.express-checkout-mode').data('express-checkout-mode');
            }
            var basketInfo = $(this).data('basket-info');
            var totalBasketInfo = basketInfo;
            var ecommerceData;
            var productsPurchase;
            if(pageContext.type.toUpperCase() === 'plp'.toUpperCase() && window.sessionStorage.ecommerce){
                ecommerceData = JSON.parse(window.sessionStorage.ecommerce);
            } else {
                ecommerceData = pageContext.analytics.basket.ecommerce;
            }
            if(ecommerceData) {
                productsPurchase = setProductsForDataLayer(ecommerceData, productsPurchase);
            }
            var analyticsEvent = {
                'event': 'begin_checkout',
                'ecommerce':{
                    'event_category' :  categoryValue,
                    'currency': pageContext.analytics.page.currencyCode,
                    'value': totalBasketInfo,
                    'im_quick_pay': expressCheckoutEnabled,
                    'items': productsPurchase
                }
            }
            dataLayer.push(analyticsEvent); 
        });
    },

    'addShippingInfo': function(analyticsData = {}){
        $(document).on('click', '.js-submit-checkout', function (e) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            e.stopPropagation();;
            if($("form.checkout-shipping").validate().errorList.length>0){
                return;
            }
            var categoryValue='funnel_delivery';
            var shippingMethod= $('.js-shippingFormHeader').text();
            localStorage.setItem('selectedShippingCombinedValue', shippingMethod);
            var coupon= $('#coupon-code .valid-coupon').map(function() { return $(this).data('coupon'); }).get().join(',');
            var productsPurchase = setProductsForDataLayer(pageContext.analytics.basket.ecommerce, productsPurchase);
            var analyticsEvent = {
                'event': 'add_shipping_info',
                'ecommerce':{
                    'event_category' :  categoryValue,
                    'currency': pageContext.analytics.page.currencyCode,
                    'value': $(this).data('basket-info'),
                    'coupon': (coupon ? coupon : undefined),
                    'shipping_tier': (shippingMethod ? shippingMethod : ''),
                    'items': productsPurchase
                }
            }
            dataLayer.push(analyticsEvent); 
        });
    },

    'addPaymentInfo': function(analyticsData = {}){
        $(document).on('click', '.js-billing-submit', function (e) {
            if($('.adyen-checkout__label__text--error').length > 0 || $('.adyen-checkout__input--large').hasClass('error') || !$('.adyen-checkout__card__holderName').hasClass('adyen-checkout__field--valid')){
                return;
            }
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            e.stopPropagation();
            var categoryValue='funnel_payment';
            var coupon= $('#coupon-code .valid-coupon').map(function() { return $(this).data('coupon'); }).get().join(',');
            var productsPurchase = setProductsForDataLayer(pageContext.analytics.basket.ecommerce, productsPurchase)
            var paymentCheckedRadio = $('.js-payment-method.input-radio:checked')[0].defaultValue, paymentType='';
            var paymentMethodSelected = $('#paymentMethodsList .paymentMethod input:checked').val();
            if(paymentCheckedRadio.toLowerCase() == 'adyencomponent'){
                if(paymentMethodSelected.toLowerCase().includes('storedcard')){
                    paymentType = 'Card';
                } else {
                    paymentType = paymentMethodSelected;
                }
            } else if(paymentCheckedRadio.toLowerCase() == 'globale_integration'){
                paymentType='Globale_Integration';
            } else if(paymentCheckedRadio.toLowerCase() == 'paypal'){
                paymentType='PayPal';
            } else if(paymentCheckedRadio.toLowerCase() == 'dw_apple_pay'){
                paymentType='Apple_Pay';
            }
            var analyticsEvent = {
                'event': 'add_payment_info',
                'ecommerce':{
                    'event_category' : categoryValue,
                    'currency': pageContext.analytics.page.currencyCode,
                    'value': $(this).data('basket-info'),
                    'coupon': (coupon ? coupon : undefined),
                    'payment_type': paymentType,
                    'items': productsPurchase
                }
            }
            dataLayer.push(analyticsEvent);
        })
    },

    'loginPage': function(analyticsData = {}){
        if (utils.getParameterValueFromUrl('loginSuccess', window.location.search)) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var analyticsEvent = {
                'event': 'login',
                'eventData':{
                    'event_category': ((analyticsData.page.pageCategory === 'checkoutlogin' || analyticsData.page.pageCategory === 'checkout') ? 'funnel_account' : 'login'),    
                    'method': 'Email'
                }
            }
            dataLayer.push(analyticsEvent);
        }
    },

    'loginPageError': function (analyticsData = {}) {
        if ($('.js-login__form').find('.error-form').length) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'login_error',
                'eventData':{
                    'event_category': ((analyticsData.page.pageCategory === 'checkoutlogin' || analyticsData.page.pageCategory === 'checkout') ? 'funnel_account' : 'login'),  
                    'error_description': $('.js-login__form').find('.error-form').html().trim(),
                    'method': 'Email'
                }
            });
        }
        var errorsCache = {};
        $('form#dwfrm_login').on('invalid-form.validate', function (event, $validator) {          
            var errors = $validator.errorList,errorflag='false';
            if (errors) {
                var errorMessagesList = '';
                var errorMessageArr = iterateErrors(errors, errorsCache, errorflag);
                errorMessagesList = errorMessageArr[0];
                errorflag = errorMessageArr[1];
                if(errorflag == 'false'){
                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                    dataLayer.push({
                        'event': 'login_error',
                        'eventData': {
                            'event_category': ((analyticsData.page.pageCategory === 'checkoutlogin' || analyticsData.page.pageCategory === 'checkout') ? 'funnel_account' : 'login'),  
                            'method': 'Email',
                            'error_description': errorMessagesList
                        }
                    });
                }
                
            }
        });
    },

    'registerPage': function(analyticsData = {}){
        if (utils.getParameterValueFromUrl('registerSuccess', window.location.search)) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var preferences = utils.jsonParse(pageContext.user.subscriptionPreferences);
            var preferencesValue = (Object.keys(preferences).filter(i => Number(preferences[i]) > 0 ?  i : 0)).join('|');
            preferencesValue = preferencesValue == "" ? 'not apply' : preferencesValue;
            var analyticsEvent = {
                'event': 'sign_up',
                'eventData':{
                    'event_category': 'register',
                    'method': 'Email',
                    'type_subscribe': preferencesValue
                }
            }
            dataLayer.push(analyticsEvent);
        }
    },

    'registerPageError': function (analyticsData = {}) {
        var errorsCache = {};
        $('form#RegistrationForm').on('invalid-form.validate', function (event, $validator) {
            var errors = $validator.errorList,errorflag='false';
            if($(this).parents('.c-account').length > 0){
                return;
            }
            if (errors) {
                var errorMessagesList = '';
                var errorMessageArr = iterateErrors(errors, errorsCache, errorflag);
                errorMessagesList = errorMessageArr[0];
                errorflag = errorMessageArr[1];
                if(errorflag == 'false'){
                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                    dataLayer.push({
                        'event': 'sign_up_error',
                        'eventData': {
                            'event_category': 'register',
                            'method': 'Email',
                            'error_description': errorMessagesList
                        }
                    });
                }
            }
        });

        $(document).on('existingAccountError.success', function (e, $form, errors) { 
            if(errors.errorMsgs.length > 0){
                var errorMessagesList = errors.errorMsgs[0];
                dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                dataLayer.push({
                    'event': 'sign_up_error',
                    'eventData': {
                        'event_category': 'register',
                        'method': 'Email',
                        'error_description': errorMessagesList
                    }
                });

            }
        });
    },

    'myAccountFormErrors': function (analyticsData = {}) {
        var errorsCache = {};
        $('.pt_account form').on('invalid-form.validate', function (event, $validator) {
            var errors = $validator.errorList,errorflag='false';
            if($(this).parents('.c-footer').length > 0 || $(this).parents('.c-login__container').length > 0 || $(this).parents('.c-registration').length > 0 || (pageContext.siteId.indexOf("FCN") > -1 && $(this).hasClass('js-edit-account'))){
                return;
            }
            if (errors) {
                var errorMessagesList = '';
                var errorMessageArr = iterateErrors(errors, errorsCache, errorflag);
                errorMessagesList = errorMessageArr[0];
                errorflag = errorMessageArr[1];
                var ecategory = 'my_account'
                if(errorflag == 'false'){
                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                    var analyticsRequiredData = {
                        'event': 'error_form',
                        'eventData': {
                            'event_category': ecategory,
                            'error_description': errorMessagesList
                        }
                    }
                    dataLayer.push(analyticsRequiredData);
                }
            }
        });
    },

    'myAccountFormPopupErrors': function (analyticsData = {}) {
        var errorsCache = {};
        $(document).on('click', '#account-address-apply-button, #account-create-account-changes-button', function (event) {
            
            var $form = $(this).parents('form');
            if($(this).parents('.c-footer').length > 0 || $(this).parents('.c-login__container').length > 0|| $(this).parents('.c-registration').length > 0){
                return;
            }
            if ($form.valid()){
                return;
            }
            var errors = $form.validate().errorList,errorflag='false';
            if (errors) {
                var errorMessagesList = '';
                var errorMessageArr = iterateErrors(errors, errorsCache, errorflag);
                errorMessagesList = errorMessageArr[0];
                errorflag = errorMessageArr[1];
                var ecategory = 'my_account'
                if(errorflag == 'false'){
                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                    var analyticsRequiredData = {
                        'event': 'error_form',
                        'eventData': {
                            'event_category': ecategory,
                            'error_description': errorMessagesList
                        }
                    }
                    dataLayer.push(analyticsRequiredData);
                }
            }
        });
    },

    'createAccountPage': function(analyticsData = {}){
        $(document).on('click', '#account-create-button', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var analyticsEvent = {
                'event': 'go_create_account',
                'eventData':{
                    'event_category': 'login'
                }
            }
            dataLayer.push(analyticsEvent);
        });
    },

    'checkoutGuestLogin': function(analyticsData = {}){
        $(document).on('click', '#account-checkout-guest-button', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var analyticsEvent = {
                'event': 'go_guest',
                'eventData':{
                    'event_category': 'funnel_account'
                }
            }
            dataLayer.push(analyticsEvent);
        });
    },

    'formCehckoutValidationErrors': function (analyticsData = {}) {
        var errorsCache = {};
        $('form').on('invalid-form.validate', function (event, $validator) {
            var errors = $validator.errorList,errorflag='false';
            if (errors) {
                var errorMessagesList = '';
                var errorMessageArr = iterateErrors(errors, errorsCache, errorflag);
                errorMessagesList = errorMessageArr[0];
                errorflag = errorMessageArr[1];
                var ecategory = analyticsData.checkout && analyticsData.checkout.step === "2" ? 'funnel_delivery' : 'funnel_payment';
                if(errorflag == 'false'){
                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                    var analyticsRequiredData = {
                        'event': 'error_form',
                        'eventData': {
                            'event_category': ecategory,
                            'error_description': errorMessagesList
                        }
                    }
                    if(ecategory == 'funnel_payment'){
                        analyticsRequiredData.eventData.im_basket_id = $('#billingSubmitButton').data('basket-id');
                    }
                    dataLayer.push(analyticsRequiredData);
                }
            }
        });
    },
    'formBillingValidationErrors' : function(analyticsData = {}){
        if ($('.js-server-side-error').length) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'error_form',
                'eventData': {
                    'event_category': 'funnel_payment',
                    'error_description': $('.js-payment-method:checked').val(),
                    'im_basket_id': $('.js-billing-submit').data('basket-id')
                }
            });
        };
    },
    'subscribeFooterSection': function () {
        $(document).on('newsletterSubscription.success', function (event, $form) {     
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});       
            var preference = '',
                preferences = '',
                ecategory = '';
            
            if((window.location.href.indexOf("subscription") > -1) || (window.location.href.indexOf("Subscription") > -1)) {
                ecategory = 'newsletter';
            } else {
                ecategory = 'footer';
                if(pageContext.siteId.indexOf("HKT") > -1 || pageContext.siteId.indexOf("FCN") > -1){
                    preferences = 'not apply';
                }
            }

            if ($form) {
                $form.find('input[name*="_type_"]:checked').each(function () {
                    preference = $(this).siblings('.js-input-label').find('.form-row_value').text();
                    if (preference) {
                        preferences = preferences ? preferences + ', ' + preference : preference;
                    }
                });
            }
            dataLayer.push({
                'event': 'subscribe_ok',
                'eventData': {
                    'event_category': ecategory,
                    'type_subscribe': (preferences ? preferences : 'not apply')
                }
            });
        });
    },

    //subcribe to newsletter checkbox and preference on click of CTA
    'subscribeCheckboxSection': function () {
        $(document).on('newsletterSubscriptionCb.success', function (e, $form) {    
            if ($form) {
                var preference = '',preferences = '',ecategory = 'newsletter';
                $form.find('input[name*="_type_"]:checked').each(function () {
                    preference = $(this).siblings('.js-input-label').find('.form-row_value').text();
                    if (preference) {
                        preferences = preferences ? preferences + ', ' + preference : preference;
                    }
                });
                var $newsletterCheckbox='';
                if(pageContext.type.toUpperCase() == 'orderconfirmation'.toUpperCase()){
                    $newsletterCheckbox = $form.find('.order-conf-radio-btn input:checked');
                } else {
                    if(pageContext.siteId.indexOf("HKT") > -1 || pageContext.siteId.indexOf("FCN") > -1){
                        $newsletterCheckbox = $form.find('.cb-newsletter-registration-subscribe .input-checkbox');
                    } else {
                        //ppj
                        $newsletterCheckbox = $form.find('.js-newsletter-policy');
                    }
                }
                
                if ($newsletterCheckbox.is(':checked')) {
                    if (!$form.valid()){
                        return;
                    }

                if(pageContext.type.toUpperCase() == 'orderconfirmation'.toUpperCase()){
                    var $selectedVal = $newsletterCheckbox.attr('id');
                        if($selectedVal){
                            preferences = $selectedVal.charAt(0).toUpperCase() + $selectedVal.substr(1);
                        }
                            
                        if(preferences == ''){
                            return;
                        }        
                    }
                    if(!(pageContext.siteId.indexOf("HKT") > -1 || pageContext.siteId.indexOf("FCN") > -1)){
                        //ppj
                        if(preferences == ''){
                            return;
                        }
                    } 

                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null });
                    dataLayer.push({
                        'event': 'subscribe_ok',
                        'eventData': {
                            'event_category': ecategory,
                            'type_subscribe': (preferences ? preferences : 'not apply')
                        }
                    });
                } else {
                    return;
                }
            }
        });
    },

    //subscribe in notify me popup on PDP, PLP and edit items popup
    'subscribePDPNotifyPopup': function () {
        $(document).on('click', '.c-product-details__notify', function (e) {
            var $form = $(this).closest('form');
            if ($form) {
                var $lengthSelected = '';
                if($form.find('.js-email-me-attrs-list').length>0){
                    //length field is visible
                    $lengthSelected = $form.find('.js-email-me-attrs-list .c-variations__sublist.length .c-variations__subitem.selected');
                    if(!($lengthSelected.length > 0)){
                        return;
                    }
                }
                var preference = '',preferences = '',ecategory = 'newsletter';
                $form.find('input[name*="_type_"]:checked').each(function () {
                    preference = $(this).siblings('.js-input-label').find('.form-row_value').text();
                    if (preference) {
                        preferences = preferences ? preferences + ', ' + preference : preference;
                    } else {
                        preferences = 'not apply';
                    }
                });
                var $newsletterCheckbox = $form.find('.js-notifyme-acceptlettersubscription');
                if ($newsletterCheckbox.is(':checked')) {
                    if (!$form.valid()) {
                        return;
                    }
    
                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null });
                    dataLayer.push({
                        'event': 'subscribe_ok',
                        'eventData': {
                            'event_category': ecategory,
                            'type_subscribe': (preferences ? preferences : 'not apply')
                        }
                    });
                } else {
                    return;
                }
            }
        });
    },
    
    'subscribeFooterError': function () {
        var errorsCache = {};
        var ecategory = '';
            
        if((window.location.href.indexOf("subscription") > -1) || (window.location.href.indexOf("Subscription") > -1)) {
            ecategory = 'newsletter';
        } else {
            ecategory = 'footer';
        }

        $('form.c-subscribe-form').on('invalid-form.validate', function (event, $validator) {
            var errors = $validator.errorList,errorflag='false';
            if (errors) {
                var errorMessagesList = '';
                var errorMessageArr = iterateErrors(errors, errorsCache, errorflag);
                errorMessagesList = errorMessageArr[0];
                errorflag = errorMessageArr[1];
                if(errorflag == 'false'){
                    dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                    dataLayer.push({
                        'event': 'subscribe_error',
                        'eventData': {
                            'event_category': ecategory,
                            'event_description': errorMessagesList,
                        }
                    });
                }
            }
        });
    },

    'findStore': function () {
        $(document).on('click', '#find-stores a', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'find_store',
                'eventData': {
                    'event_category': 'footer'
                }
            });
        });
    },

    'couponCode': function (analyticsData = {}) {
        $(document).on('coupon.added', function (event, data) {
            var couponData = $(data).find('#coupon-code').data('analytics');
            var eventCategoryValue;
            if(pageContext.type.toLowerCase()=='cart'){
                eventCategoryValue = "funnel_cart";
            } else if(analyticsData.checkout && analyticsData.checkout.step === "2"){
                eventCategoryValue='funnel_delivery';
            } else {
                eventCategoryValue='funnel_payment';
            }

            if (couponData) {
                dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                dataLayer.push({
                    'event': 'apply_code',
                    'eventData': {
                        'event_category': eventCategoryValue,
                        'event_label': couponData.couponCode + '-' + couponData.couponStatus
                    }
                });
            }
        });
    },

    'viewCartBag': function () {
        $(document).on('click', '#minicart-viewbag-button,#add-to-cart-toast .o-btn-sm-tertiary', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'go_bag',
                'eventData': {
                    'event_category': $(this).parents('#add-to-cart-toast').hasClass('added-to-bag-modal') ? 'popup_bag':'header_bag',
                }
            });
        });
    },

    'previewMiniBag': function() {
        $(document).on('click', '.js-preview-bag', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'select_content',
                'eventData': {
                    'event_category': 'header',
                    'content_type': 'open_bag'
                }
            });
        });
    },

    'closeMiniPreviewBag': function() {
        $(document).on('minicart.close', (e, avoidAnalytics) => {
            e.preventDefault();
            e.stopPropagation();
            if (avoidAnalytics) {
                return;
            }
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'select_content',
                'eventData': {
                    'event_category': 'header',
                    'content_type': 'close_bag'
                }
            });
        });
    },

    //for attraqt filter
    'filtersClick': function(){
        $(document).on('click', '.js-refinement-subitem a', function (e) {
            let hrefValue = $(this).attr('href'); // Get the href value
            if (hrefValue && hrefValue.includes('?')) {
                dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                var pageID;
                if(pageContext.currentPage == 'category') {
                    pageID = 'category';
                } else if (pageContext.currentPage == 'search') {
                    pageID = 'search_list';
                }
                var filterPage,filterValue, label='';
                var filterLabel =  $(this).data('refinement-value');
                if ($(this).parents().hasClass('top-refinement-bar')){
                    var filterVal = $(this).parents('.primary-content').find('.c-category-header__text');
                    if(filterVal.length > 0){
                        filterPage = filterVal[0].innerHTML;
                        label = filterPage.trim()+"-"+filterLabel;
                    }
                } else{
                    var filterFacet = $(this).closest('.c-refinements__item').find('.c-refinements__item-heading-info');
                    if(filterFacet.length > 0){
                        filterValue = $(this).closest('.c-refinements__item').find('.c-refinements__item-heading-info')[0].innerHTML;
                        label = filterValue.trim()+"-"+filterLabel;
                    }
                }
                var analyticsEvent = {
                    'event': 'filter',
                    'eventData': {
                        'event_category': pageID,
                        'event_label': label
                    }
                }

                if (SitePreferences.ATTRAQT_ENABLED) {
                    var facetId = $(this).closest('.c-refinements__item').find('.c-refinements__item-heading-info').data('facet-id');
                    var facetValue = $(this).data('refinement-value');
                    var sourceIdVal = $(this).parents('#wrapper').find('#sourceId');
                    var sessionIdVal = $('#wrapper').find('#sessionId');
                    var sourceIdValue='', sessionIdValue;

                    sessionIdValue = sessionIdVal.val();  
                    if(sourceIdVal.length > 0){
                        sourceIdValue = sourceIdVal.val();
                    }

                    var attraqtdata = {
                        'action': 'click',
                        'target': {
                            'facet': facetId,
                            [facetId]: facetValue
                        },
                        'metadata': {
                            'locale': pageContext.currentLocale
                        },
                        'sourceId': sourceIdValue,
                        'sessionId': sessionIdValue
                    }
                    analyticsEvent.attraqtdata = attraqtdata;
                }

                dataLayer.push(analyticsEvent);
            }
        });
    },

    'navigationClick': function () {
        $(document).on('click', 'li.js-menu-tab-content > .js-menu-link, li.js-nav-system-subnav-item > .js-nav-system-subnav-link, a.submenu-links-title', function () {
        //$(document).on('click', 'a.submenu-links-title', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var categoryID = $(this).data('categoryId') || '';
            dataLayer.push({
                'event': 'select_category',
                'eventData': {
                    'event_category': 'header',
                    'event_label': categoryID
                }
            });
        });
    },

    'genreClick': function () {
        $(document).on('click', '.pt_categorylanding .main-content a', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var linkText = $(this).text();
            dataLayer.push({
                'event': 'select_category',
                'eventData': {
                    'event_category': 'genre',
                    'event_label': linkText
                }
            });
        });
    },

    'landingClick': function () {
        $(document).on('click', '.pt_content #main a', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var linkText = $(this).text();
            dataLayer.push({
                'event': 'select_category',
                'eventData': {
                    'event_category': 'landing',
                    'event_label': linkText
                }
            });
        });
    },

    //colourClick Event in UA - select_colour Event in ga4
    'colourClick': function (analyticsData = {}) {
        $(document).on('click', '.color > li > .js-product-variations__link, .c-product-tile__swatches .js-swatch-list > li', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var color = $(this).data('color-value');
            var color_id = $(this).data('color-id');
            var $this = $(this);
            var recommendationsCarouselData = $this.parents('.recommendations-carousel');
            var recentlyViewedDiv = $this.parents('.recently-viewed-carousel');
            var eventCategoryValue;
            if (pageContext.currentPage === 'product') {
                if (recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0){
                    eventCategoryValue = 'carousel';
                } else {
                    eventCategoryValue = 'product';
                }
            } else if (pageContext.currentPage == 'search'){
                if(recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0){
                    eventCategoryValue = 'carousel';
                } else {
                    eventCategoryValue = 'search_list';
                }  
            } else if (recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0) {
                eventCategoryValue = 'carousel';
            } else if (pageContext.currentPage == 'category'){
                eventCategoryValue = 'category';
            }
            var analyticsEvent = {
                'event': 'select_colour',
                'eventData': {
                    'event_category': eventCategoryValue,
                    'event_label': color,
                    'color_id': color_id
                }
            }
            dataLayer.push(analyticsEvent);
        });
    },
    
    //varientClick Event in UA - select_size Event in ga4
    'varientClick': function (analyticsData = {}) {
        //$(document).on('select.varient', function () {
        $(document).on('click', '#product-option-size li > .js-product-variations__link', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var $this = $(this);
            var $size = $this.text().trim();
            var recommendationsCarouselData = $this.parents('.recommendations-carousel');
            var recentlyViewedDiv = $this.parents('.recently-viewed-carousel');
            var eventCategoryValue;
            if (pageContext.currentPage === 'product') {
                if (recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0){
                    eventCategoryValue = 'carousel';
                } else {
                    eventCategoryValue = 'product';
                }
            } else if (pageContext.currentPage == 'search'){
                if(recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0){
                    eventCategoryValue = 'carousel';
                } else {
                    eventCategoryValue = 'search_list';
                }  
            } else if (recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0) {
                eventCategoryValue = 'carousel';
            } else if (pageContext.currentPage == 'category'){
                eventCategoryValue = 'category';
            }
            var analyticsEvent = {
                'event': 'select_size',
                'eventData': {
                    'event_category': eventCategoryValue,
                    'event_label': $size
                }
            }
            dataLayer.push(analyticsEvent);
        });
    },

    //varientClick Event in UA - select_length Event in ga4 
    'lengthClick': function (analyticsData = {}) {
        //$(document).on('select.length', function () {
        $(document).on('click', '#product-option-length li > .js-product-variations__link', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var $this = $(this);
            var $length = $this.text().trim();
            var recommendationsCarouselData = $this.parents('.recommendations-carousel');
            var recentlyViewedDiv = $this.parents('.recently-viewed-carousel');
            var eventCategoryValue;
            if (pageContext.currentPage === 'product') {
                if (recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0){
                    eventCategoryValue = 'carousel';
                } else {
                    eventCategoryValue = 'product';
                }
            } else if (pageContext.currentPage == 'search'){
                if(recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0){
                    eventCategoryValue = 'carousel';
                } else {
                    eventCategoryValue = 'search_list';
                }  
            } else if (recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0) {
                eventCategoryValue = 'carousel';
            } else if (pageContext.currentPage == 'category'){
                eventCategoryValue = 'category';
            }
            var analyticsEvent = {
                'event': 'select_length',
                'eventData': {
                    'event_category': eventCategoryValue,
                    'event_label': $length
                }
            }
            dataLayer.push(analyticsEvent);
        });
    },

    'paymentPageError': function () {
        $(document).on('billing.error', function (event) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var paymentError = $('.js-payment-method:checked').val();
            var analyticsEvent = {
                'event': 'error_form',
                'eventData': {
                    'event_category': 'funnel_payment',
                    'event_description': paymentError + 'Error'
                }
            }
            dataLayer.push(analyticsEvent);
        });
    },

    'internalSearch': function () {
        $(document).on('click', '#header-search-input, #header-search-button', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var analyticsEvent = {
                'event': 'internal_search',
                'eventData': {
                    'event_category': 'search',
                    'content_type': 'click_search'
                }
            }
            dataLayer.push(analyticsEvent);
        });
    },

    'internalSearchCategoryClick': function () {
        $(document).on('click', '.c-search-suggestion__list > li', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var cType;
            var catName = $(this).children('a').text().trim();
            if($(this).parents().hasClass('category-list')){
                cType = "click_category";
            } else {
                cType = "click_keyword_searches";
            }
            dataLayer.push({
                'event': 'internal_search',
                'eventData': {
                    'event_category': 'Search',
                    'content_type': cType,
                    'event_label': catName
                }
            });
        });
    },

    'searchResultsClick': function () {
        $(document).on('keypress', '#header-search-input', function () {
            var sValue;
            if(event.keyCode == 13){
                sValue = $(this).val();
                dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                dataLayer.push({
                    'event': 'search',
                    'eventData': {
                        'event_category': 'search',
                        'search_term': sValue
                    }
                });
            }
        });
    },

    'viewSearchResults': function () {
        if((pageContext.type.toUpperCase() === 'search'.toUpperCase()) && ($(".pt_product-search-result").length > 0)) {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var sValue = $('.pt_product-search-result').find('.search-hits').data("search-phrase");
            dataLayer.push({
                'event': 'view_search_results',
                'eventData': {
                    'search_term': sValue
                }
            });
        }
    },

    'navBannerClick': function () {
        if(pageContext.type.toUpperCase() === 'home'.toUpperCase()) {
            $(document).on('click', '.homepage-hero-slots,#homepage-banner-slot .highlight-wrapper,#homepage-find-store-banner', function () {
                dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                var path = $(this).find('a').attr('href');
                var label = $(this).find('p:first-child').text().trim();
                dataLayer.push({
                    'event': 'select_content',
                    'eventData': {
                        'event_category': 'home',
                        'content_type': 'click_banner',
                        'event_label': label,
                        'destination_path': path,
                    }
                });
            });
        }
    },

    /* PPJ CLPs Select_Content Events */
    'clpBannerClick': function () {
        if(pageContext.type.toUpperCase() === 'clp'.toUpperCase()) {
            $(document).on('click', '#clp-hero-slots,.clp-slots .clp-section .highlight-wrapper,.clp-slots .clp-split-banner,.clp-slots #slotbanner-video', function () {
                dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
                var path = $(this).find('a').attr('href');
                var label = $(this).find('p:first-child').text().trim();
                dataLayer.push({
                    'event': 'select_content',
                    'eventData': {
                        'event_category': 'genre',
                        'content_type': 'click_banner',
                        'event_label': label,
                        'destination_path': path,
                    }
                });
            });
        }
    },

    /* HKT-FCN Homepage Select_Content Events */
    'navHktBannerClick': function () {
        if(pageContext.type.toUpperCase() === 'home'.toUpperCase()) {
            $(document).on('click', '.homepage-slots .alt-brand .div-relative,.homepage-slots .hp-image-banner .row-highlight, .our-brand .row-highlights-three > div', function () {
                dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null}); 
                var path = $(this).find('a').attr('href');
                var label = $(this).find('.labeltitle').text().trim();
                dataLayer.push({
                    'event': 'select_content',
                    'eventData': {
                        'event_category': 'home',
                        'content_type': 'click_banner',
                        'event_label': label,
                        'destination_path': path,
                    }
                });
            });
        }
    },

    'addtobagProductTile': function () {
        $(document).on('click', 'a.add-bag', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var productName = $(this).parents('.product-tile').find('.c-product-tile__name').text().trim();
            var $this = $(this);
            var recommendationsCarouselData = $this.parents('.recommendations-carousel');
            var recentlyViewedDiv = $this.parents('.recently-viewed-carousel');
            var eventCategoryValue;
            if (pageContext.currentPage === 'product') {
                if (recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0){
                    eventCategoryValue = 'carousel';
                } else {
                    eventCategoryValue = 'product';
                }
            } else if (pageContext.currentPage == 'search'){
                if(recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0){
                    eventCategoryValue = 'carousel';
                } else {
                    eventCategoryValue = 'search_list';
                }  
            } else if (recentlyViewedDiv.length > 0 || recommendationsCarouselData.length > 0) {
                eventCategoryValue = 'carousel';
            } else if (pageContext.currentPage == 'category'){
                eventCategoryValue = 'category';
            }
            dataLayer.push({
                'event': 'select_content',
                'eventData': {
                    'event_category': eventCategoryValue,
                    'content_type': 'click_button_add',
                    'event_label': productName
                }
            });
        });
    },

    'socialFooterClick': function () {
        $(document).on('click', '.social-media-icons a, .js-footer-social a, .follow-us a', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'select_content',
                'eventData': {
                    'event_category': 'footer',
                    'content_type': 'click_social_network',
                    'event_label': $(this).attr('title')
                }
            });
        });
    },

    'genreBannerClick': function () {
        $(document).on('click', '.pt_categorylanding .clp-hero-slots a', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var linkText = $(this).parents('.hero-banner').find('.display-1').text().trim();
            dataLayer.push({
                'event': 'select_content',
                'eventData': {
                    'event_category': 'genre',
                    'event_label': linkText,
                    'destination_path': $(this).attr("href"),
                    'content_type': 'click_banner'
                }
            });
        });

        $(document).on('click', '#header-top-banner a', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var linkText = $(this).text().trim();
            dataLayer.push({
                'event': 'select_content',
                'eventData': {
                    'event_category': 'genre',
                    'event_label': linkText,
                    'destination_path': $(this).attr("href"),
                    'content_type': 'click_banner'
                }
            });
        });
    },

    'checkoutGoPayment': function () {
        $(document).on('click', '.js-submit-checkout', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'select_content',
                'eventData': {
                    'event_category': 'funnel_delivery',
                    'content_type': 'go_payment'
                },
            });
        });
    },

    'checkoutPlaceOrder': function () {
        $(document).on('click', '.js-billing-submit', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            dataLayer.push({
                'event': 'select_content',
                'eventData': {
                    'event_category': 'funnel_ payment',
                    'content_type': 'place_order'
                }
            });
        });
    },

    'landingBannerClick': function () {
        $(document).on('click', '.pt_content .hero-banner a', function () {
            dataLayer.push({ ecommerce: null, eventData: null, attraqtdata: null});
            var linkText = $(this).parents('.hero-banner').find('.display-1').text().trim();
            dataLayer.push({
                'event': 'select_content',
                'eventData': {
                    'event_category': 'landing',
                    'event_label': linkText,
                    'destination_path': $(this).attr("href"),
                    'content_type': 'click_banner'
                }
            });
        });
    },
};

	
