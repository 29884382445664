var mappings = require('./mappings.json');
var coreExtUtils = require('./../../util');

module.exports = {
    /**
     * @description starts functions execution from list of provided functions
     * @param functionsList {Array} List of functions names
     * @param ObjectWithFunctions {Object} contains collection of named functions
     */
    initFunctionsFromObject: function (functionsList, ObjectWithFunctions, args) {
        if (functionsList && ObjectWithFunctions) {
            var functionsListLength = functionsList.length,
                i = 0;

            for (; i < functionsListLength; i++) {
                var functionName = functionsList[i];
                if (functionName in ObjectWithFunctions) {
                    ObjectWithFunctions[functionName].apply(this, args);
                }
            }
        }
    },
    /**
     * @description replace values to given value from object
     * @param obj {Object} Object
     * @param value {String} value of key against which needs to compare
     * @param replacer {String} replace value
     */
    updateObjectValues: function (obj, value, replacer) {
        for (const i in obj) {
            if (Array.isArray(obj[i]) || typeof obj[i] === 'object') {
                module.exports.updateObjectValues(obj[i], value, replacer);
            } else if (obj[i] === value) {
                obj[i] = replacer;
            }
        }
        return obj;
    },

    createProductObject: function (sourceObject) {
        return {
            'position': sourceObject.position,
            'name': sourceObject.name,
            'id': sourceObject.id,
            'masterId': sourceObject.masterId,
            'price': sourceObject.price,
            'brand': sourceObject.brand,
            'category': sourceObject.category,
            'variant': sourceObject.variant,
            'dimension15': sourceObject.dimension15,
            'length': sourceObject.length,
            'list': sourceObject.list
        };
    },
    /**
     * Check is current impression is visible inside viewport
     * @param {any} $impression
     * @returns {boolean}
     */
    isImpressionVisible: function ($impression) {
        let elementTop = $impression.offset().top,
            elementBottom = elementTop + $impression.outerHeight(),
            viewportTop = $(window).scrollTop(),
            viewportBottom = viewportTop + $(window).height();

        if($impression.parents('.recommendations-carousel')) {
            let rect = $impression[0].getBoundingClientRect();
            return elementBottom > viewportTop && elementTop < viewportBottom && rect.left >= -1 && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
        } else {
            return elementBottom > viewportTop && elementTop < viewportBottom;
        }
    },
    /**
     * @function private
     * @description returns object with data collected on checkout pages
     * @param {Object} analyticsData, data passed from backend
     * @param {Number} step, current checkout step
     * @param {String} option, for example selected shipping method or payment type
     * @return {Object} productData
     */
    getCheckoutData: function (analyticsData, step) {
        var products = [];
        for (var i = 0; i < analyticsData.basket.ecommerce.length; i++) {
            var product = analyticsData.basket.ecommerce[i];
            var formattedProductData = this.createProductObject(product);
            formattedProductData.quantity = product.quantity;
            formattedProductData.dimension28 = product.list;
            formattedProductData.item_grid_type = product.item_grid_type;
            products.push(formattedProductData);
        }

        return {
            'currencyCode': analyticsData.page ? analyticsData.page.currencyCode : '',
            'event': 'checkout',
            'checkout': {
                'actionField': {
                    'step': step
                },
                'products': products
            }
        };
    },

    /* eslint-disable */
    getAdvancePageData: function (analyticsData, ecommerce) {
        return {
            'event': 'pageview',
            'system': analyticsData.systemData,
            'store': analyticsData.storeData,
            'content': analyticsData.contentData,
            'user': pageContext.user,
            'category': analyticsData.categoryData || mappings.category[pageContext.currentPage],
            'extraData': analyticsData.extraData || undefined,
            'ecommerce': ecommerce || undefined
        };
    },

    getPageData: function (analyticsData) {
        return {
            'event': 'pageview',
            'system': analyticsData.systemData,
            'store': analyticsData.storeData,
            'content': analyticsData.contentData,
            'user': pageContext.user,
            'category': mappings.category[pageContext.currentPage],
            'extraData': undefined,
            'ecommerce': undefined
        };
    },
    /* eslint-enable */

    getNewAdvancePageData: function (analyticsData) {
        var pageTypeValue = pageContext.dlPageType, contentgroupValue='',dlpageValue='';
        var sessionIdVal = $('#wrapper').find('#sessionId');
        var sessionIdValue = sessionIdVal.val();
        if(!pageContext.hasOwnProperty('dlPageType')){
            pageTypeValue = pageContext.type;
        }
        if(pageContext.dlContentGroup){
            contentgroupValue = pageContext.dlContentGroup
        }
        if(pageContext.analytics.contentData){
            dlpageValue = 'im/' + pageContext.analytics.contentData.pageType.toLowerCase() + window.location.pathname;
            if(pageContext.type.toUpperCase() === 'search'.toUpperCase()) {
                //for search/nosearch result page and search term
                const urlgetparams = new URLSearchParams(window.location.search);
                var searchterm = urlgetparams.get('q');
                dlpageValue = 'im/' + pageContext.analytics.contentData.pageType.toLowerCase() + "/" + pageContext.currentLocale.toLowerCase() + "/" + pageContext.dlSearchResult +"?q=" + searchterm;
                if(pageContext.dlSearchResult.toUpperCase() == 'noresults'.toUpperCase()){
                    dlpageValue = 'im/404-not-found/'+ pageContext.currentLocale.toLowerCase();
                }
            } else if(pageContext.type.toUpperCase() === 'product'.toUpperCase()) {
                //for pdp page
                dlpageValue = dlpageValue.split('.html')[0];
            } else if(pageContext.type.toUpperCase() === 'orderconfirmation'.toUpperCase()) {
                //for order confirmation
                 dlpageValue = 'im/orderconfirmation/'+ analyticsData.storeData.language.toLowerCase() + '/order-confirmation';
            }
            //To have consistency across all pages ex- across all pages it contains en_gb and not gb
            if(pageContext.type.toUpperCase() === 'storefront'.toUpperCase()) {
                //for help and company pages
                dlpageValue = 'im/'+ analyticsData.storeData.language.toLowerCase() + analyticsData.contentData.pageTitle;
                pageTypeValue = 'other';
            } else {
                dlpageValue = dlpageValue.replace(dlpageValue.split("/")[2], analyticsData.storeData.language.toLowerCase());
            }
        }

        var genreValue  = '';
        if(pageContext.analytics.categoryData){
            var searchResultItems = $('#search-result-items');
            if(searchResultItems.find('.js-product-impression').length > 0 && !(pageContext.type.toUpperCase() === 'search'.toUpperCase())){
                var searchImpressionsData = searchResultItems.find('.js-product-impression').data('product-details');
                var searchImpressionsGender = searchImpressionsData.gendername.toLowerCase();
                genreValue = (searchImpressionsGender ? searchImpressionsGender : '');
            } else if(pageContext.type.toUpperCase() === 'product'.toUpperCase()) {
                genreValue = (pageContext.analytics.product.gendername ? pageContext.analytics.product.gendername.toLowerCase() : '');
            } else if(pageContext.type.toUpperCase() === 'clp'.toUpperCase()){
                genreValue = (pageContext.analytics.categoryData.gender ? pageContext.analytics.categoryData.gender.toLowerCase() : '');
            } else {
                genreValue = 'not apply';
            }
        } else {
            genreValue = 'not apply';
        }
        
        var advanceData = {
            'event': 'page_view',
            'context': {
                page: (dlpageValue ? dlpageValue : ''),
                url: location.href,
                content_group: (contentgroupValue ? contentgroupValue : ''),
                im_page_type: (pageTypeValue ? pageTypeValue : ''),
                country: analyticsData.storeData.store,
                im_genre: (genreValue ? genreValue : ''),
                user_status: pageContext.user.loginState,
                store: analyticsData.storeData.language.toLowerCase()
            }
        }
        if(pageContext.type.toUpperCase() === 'product'.toUpperCase()) {
            if (SitePreferences.ATTRAQT_ENABLED) {
                var sourceIdValue = '';
                var sourceIdVal = $('#wrapper').find('#sourceId');
                if(sourceIdVal.length > 0){
                    sourceIdValue = sourceIdVal.val();
                }
                var attraqtdata = {
                    'action': 'view',
                    'target': {
                        'product': pageContext.currentProductID
                    },
                    'metadata': {
                        'locale': pageContext.currentLocale
                    },
                    'sourceId': sourceIdValue,
                    'sessionId': sessionIdValue
                }
                advanceData.attraqtdata = attraqtdata;
            }
        }

        if(pageContext.type.toUpperCase() === 'plp'.toUpperCase() || pageContext.type.toUpperCase() === 'search'.toUpperCase() || pageContext.type.toUpperCase() === 'clp'.toUpperCase()) {
            if (SitePreferences.ATTRAQT_ENABLED) {
                var pageToPath = analyticsData.contentData.pageTitle;
                var sourceIdVal = $('.pt_product-search-result').find('#sourceId');
                var sourceIdValue, sessionIdValue;

                    if(sourceIdVal.length > 0){
                        sourceIdValue = sourceIdVal.val(); 
                    }
                
                var attraqtdata = {
                    'action': 'view',
                    'target': {
                        'page': pageToPath,
                    },
                    'metadata': {
                        'locale': pageContext.currentLocale
                    },
                    'sourceId': sourceIdValue,
                    'sessionId': sessionIdValue
                }
                 
                var searchTermFromUrl = coreExtUtils.getParameterValueFromUrl('q', window.location.search);
                if (searchTermFromUrl) {
                    var searchTerm = searchTermFromUrl;
                    attraqtdata.target['search-terms'] = searchTerm;
                }
                advanceData.attraqtdata = attraqtdata;
            }
        }

        if(pageContext.user.loginState=='logged'){
            var user = {
                'im_user_id': pageContext.user.id,
                'im_sha256_email': pageContext.user.mhash,
                'im_email': pageContext.user.email
            }
            advanceData.user = user;
        }
        return advanceData;
    }
};