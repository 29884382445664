import Block from '_core_ext/core/common/Block';

export default class ShippingMethod extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        $('.js-shippingMethod').first().addClass('active');
        this.event('click', '.js-shipping-method__option', this.onClick);
        // eslint-disable-next-line max-len
        var savedAddressLength = $('.c-checkout__content .shippingMethodSelector .c-shipping-address__list').find('address.js-personal-data').length;
        if (savedAddressLength === 0) {
            $('.c-checkout__content').find('.shippingMethodSelector.deliveryAddress').css({'padding': '0', 'margin': '0'});
        }
    }

    onClick (el) {
        this.emitter.emit('shippingMethod.selected', this.getShippingMethodInfo(), $(el));
        $('.js-shippingMethod').removeClass('active');
        this.addClass('active');
        localStorage.setItem('selectedShippingMethodValue',this.$el.data('json-config').shippingMethodID);
    }

    getShippingMethodInfo () {
        return {
            shippingMethodId: this.config.shippingMethodID,
            shippingMethodName: this.config.shippingMethodName,
            shippingMethodDescription: this.config.shippingMethodDescription,
            shippingMethodDeliveryOption: this.config.deliveryOption
        };
    }

    updatefterCouponSubmit (shippingCostData, isCouponError) {
        var $standard = this.$el.find('.js-shipping-method-standard');
        var $discount = this.$el.find('.js-shipping-method-discount');
        var $surcharge = this.$el.find('.js-shippingsurcharge');
        var $ShippingMethodInfo = this.$el.find('.js-shipping-method__item-info');
        var cssClass = null;
        var $calloutMsg;

        $standard.addClass('is--hidden');
        $standard.text('');
        $discount.removeClass('c-shipping-method__free c-shipping-method__discount');
        cssClass = 'c-shipping-method__free';
        if (shippingCostData.shippingPriceAdjustmentsTotal) {
            $standard.removeClass('is--hidden');
            $standard.text(shippingCostData.strBaseShipping);

            if (shippingCostData.strBaseShipping) {
                cssClass = 'c-shipping-method__discount';
            }
            if (shippingCostData.baseShippingAdjusted) {
                cssClass += ' value-new';
                $discount.text(shippingCostData.strBaseShippingAdjusted);
            } else {
                $discount.text(shippingCostData.singleshippingFreeshippingMsg);
            }
        } else if (!shippingCostData.baseShipping) {
            $discount.text(shippingCostData.singleshippingFreeshippingMsg);
        } else {
            $discount.text(shippingCostData.strBaseShipping);
        }
        $discount.addClass(cssClass);

        $surcharge.removeClass('is--hidden');
        if (shippingCostData.surchargeAdjusted > 0) {
            $surcharge.text(' + ' + shippingCostData.strSurchargeAdjusted + ' ' + shippingCostData.singleshippingSurchargeMsg);
        } else {
            $surcharge.addClass('is--hidden');
            $surcharge.text('');
        }

        if (shippingCostData.shippingCalloutMsg) {
            if (!isCouponError) {
                $calloutMsg = $('<div></div>')
                    .addClass('c-products-item__promo-adjustment js-shipping-method-calloutmsg')
                    .text(shippingCostData.shippingCalloutMsg);
                $ShippingMethodInfo.prepend($calloutMsg);
            }
        } else {
            $calloutMsg = $ShippingMethodInfo.find('.js-shipping-method-calloutmsg');
            if ($calloutMsg.length) {
                $calloutMsg.remove();
            }
        }
    }
}